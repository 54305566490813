export default {
  path: 'financial-management',
  name: 'chat-settings-financial-management',
  redirect: '/chat-settings/financial-management/credits',
  component: () => import('./FinancialManagement.vue'),
  children: [
    {
      path: 'credits',
      name: 'chat-settings-financial-management-credits',
      redirect: '/chat-settings/financial-management/credits/resume',
      component: () => import('./credits/components/CreditsContainer.vue'),
      children: [
        {
          path: 'resume',
          name: 'chat-settings-financial-management-credits-resume',
          component: () =>
            import('./credits/components/resume/CreditsResume.vue')
        },
        {
          path: 'recurring-subscription-details',
          name: 'chat-settings-financial-management-credits-recurring-subscription-details',
          component: () =>
            import(
              './credits/components/recurring-subscription-details/RecurringSubscriptionDetails.vue'
            )
        }
      ]
    }
  ]
}
