import { CommerceOnboardingState } from '@/store/interfaces'

export const commerceOnboardingCurrentStep = (state: CommerceOnboardingState) =>
  state.currentOnboardingStep

export const commerceOnboardingIntialDataLoader = (
  state: CommerceOnboardingState
) => state.initialDataLoader

export const commerceOnboardingAvailableSectors = (
  state: CommerceOnboardingState
) => state.selectsOptions.availableSectors

export const commerceOnboardingAvailableTools = (
  state: CommerceOnboardingState
) => state.selectsOptions.availableTools

export const commerceOnboardingCompanySectorId = (
  state: CommerceOnboardingState
) => state.companySectorId

export const commerceOnboardingCompanyCommerceType = (
  state: CommerceOnboardingState
) => state.companyCommerceType

export const commerceOnboardingAboutCompany = (
  state: CommerceOnboardingState
) => state.aboutCompany

export const commerceOnboardingCompanyTools = (
  state: CommerceOnboardingState
) => state.companyToolsIds

export const commerceOnboardingOthersTools = (state: CommerceOnboardingState) =>
  state.otherTools
