import {
  FilterTypes,
  CommerceEnum
} from '@/modules/Chat/components/conversation/enums'
import { CommerceMethodType } from '@/modules/Chat/components/conversation/enums/commerce'

export const commerceCatalogLoader = ({ commerce }) => commerce.catalogLoader

export const commerceCatalog = ({ commerce }) => commerce.catalog

export const commerceHasCatalog = ({ commerce }) =>
  Object.entries(commerce.catalog).length !== 0

export const commerceCatalogHasIntegration = ({ commerce }) => {
  return !!commerce.catalog?.integrations?.length
}

export const commerceProducts = ({ commerce }) => commerce.products.items

export const commerceProductsPage = ({ commerce }) => commerce.products.page

export const commerceAllProductsLoaded = ({ commerce }) =>
  commerce.products.allProductsLoaded

export const commerceProductsPaginateLoader = ({ commerce }) =>
  commerce.products.paginateLoader

export const commerceCanSendProduct = (
  { commerce },
  { integratorWhatsappNumbers }
) =>
  integratorWhatsappNumbers.length && !!commerce.catalog?.integrations?.length

export const commerceProductDetails = ({ commerce }) => commerce.productDetails

export const commerceProductDetailsAllAttributeSelected = ({ commerce }) =>
  commerce.productDetails.attributes.every(attribute => {
    return attribute.values.some(variation => variation.selected)
  })

export const commerceFilterLoader = ({ commerce }) => commerce.filter.loader

export const commerceFilters = ({ commerce }) => commerce.filter.items

export const commerceFilteredProducts = ({ commerce }) =>
  commerce.products.filteredItems

export const commerceSelectedCheckboxFilters = (
  _,
  { commerceFilters: filters }
) => {
  const selectedFilters = []

  filters.forEach(filter => {
    if (
      filter.type === FilterTypes.Checkbox ||
      !Object.prototype.hasOwnProperty.call(filter, 'type')
    ) {
      const selectedValues = filter.values.filter(item => item.selected)

      if (selectedValues.length) {
        selectedFilters.push({
          ...filter,
          values: selectedValues
        })
      }
    }
  })

  return selectedFilters
}

export const commerceFilterQueryString = ({ commerce }) =>
  commerce.filter.queryString

export const commerceOrderBy = ({ commerce }) => commerce.orderBy

export const commerceSelectedProducts = ({ commerce }) =>
  commerce.selectedProducts

export const commerceSelectedProductsPerChat = ({ commerce, chat }) =>
  commerce.selectedProducts.find(
    chatProducts => chatProducts.chatKey === chat.key
  )?.items || []

export const commerceSelectedProductsPerChatGroup = (
  _,
  { commerceSelectedProductsPerChat: products }
) => {
  const productsGroupByCatalogs = []

  if (products.length) {
    products.forEach(product => {
      productsGroupByCatalogs.push({ ...product })
    })
  }

  return productsGroupByCatalogs
}

export const commerceProductsSended = ({ commerce }) => commerce.productsSended

export const commerceCart = ({ commerce }) => commerce.cart

export const commerceCartLoader = ({ commerce }) => commerce.cartLoader

export const commerceLimitOfProductsReached = (
  _,
  { commerceSelectedProductsPerChat: products }
) => products.length >= CommerceEnum.MaxProdutctsSend

export const commerceMethodType = ({ commerce }) => commerce.type

export const commerceMethodTypeIsSendProducts = ({ commerce }) =>
  commerce.type === CommerceMethodType.sendProducts

export const commerceMethodTypeIsCreateCart = ({ commerce }) =>
  commerce.type === CommerceMethodType.createCart

export const commerceCreateCartProducts = ({ commerce }) =>
  commerce.createCart.products

export const commerceCreateCartCheckout = ({ commerce }) =>
  commerce.createCart.checkout

export const commerceCreateCartOrder = ({ commerce }) =>
  commerce.createCart.order
