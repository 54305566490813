import { createSequentialEnum } from '@/modules/Chat/helpers/enum'

export const AddContactModalStep = createSequentialEnum(
  'ContactDetail',
  'ExistingContactsList',
  'SelectDataToSave',
  'SelectDataToUpdate',
  'SuccessUpdateContact',
  'ErrorUpdateContact',
  'AskForNewOrExisting',
  'SelectExistingContact',
  'SuccessCreateContact',
  'ErrorCreateContact',
  'CreateContactForm',
  'SelectNumberToStartConversation'
)
