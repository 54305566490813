import * as Agent from './agent'
import * as Chat from './chat'
import * as Groups from './groups'
import * as Integrator from './integrator'
import * as Mixpanel from './mixpanel'
import * as Settings from './settings'
import * as Statistics from './statistics'
import * as Whatsapp from './whatsapp'

export default {
  ...Agent,
  ...Chat,
  ...Groups,
  ...Integrator,
  ...Mixpanel,
  ...Settings,
  ...Statistics,
  ...Whatsapp
}
