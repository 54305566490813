import { RoleTypes } from '@/common/helpers/enums'

export default {
  path: '/analytics',
  name: 'analytics',
  redirect: '/analytics/dashboard',
  component: () => import('@/views/Dashboard.vue'),
  children: [
    {
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/modules/Dashboard/Overview.vue'),
      meta: {
        submenuIndex: 0,
        internalRoute: 'overview',
        permissionLevel: RoleTypes.Admin
      }
    },
    {
      path: 'reports',
      name: 'reports',
      meta: {
        // title: 'page.reports.title',
        iframeId: 'Main',
        internalRoute:
          'embed/main/home/reports/teekitApps/505c8312-637a-11e8-adc0-fa7ae01bbebc/',
        permissionLevel: RoleTypes.Admin
      }
    },
    {
      path: 'nf-box-report',
      name: 'nf-box-report',
      meta: {
        iframeId: 'Main',
        internalRoute: 'embed/main/home/nfbox/report//',
        permissionLevel: RoleTypes.Admin
      }
    },
    {
      path: 'octa-timer-report',
      name: 'octa-timer-report',
      meta: {
        iframeId: 'Main',
        internalRoute: 'embed/main/home/ticket-timer/report//',
        permissionLevel: RoleTypes.Agent
      }
    }
  ]
}
