import { User } from '@/common/helpers/interfaces'

import { UserState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.UPDATE_USER](state: UserState, user: User) {
    state.user = user
  }
}
