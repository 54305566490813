import { ActionContext } from 'vuex'

import { User } from '@/common/helpers/interfaces'

import UsersService from '@/common/services/users'

import { RootState, UserState } from '@/store/interfaces'

import * as types from './mutations-types'

export const fetchUser = async (
  context: ActionContext<UserState, RootState>,
  userId: string
) => {
  const userService = new UsersService()
  const user: User = await userService.getUser(userId)

  context.commit(types.UPDATE_USER, user)
}

export const updateUser = async (
  context: ActionContext<UserState, RootState>,
  payload: Record<string, any>
) => {
  const formData = new FormData()

  for (const key in payload) {
    if (payload[key] || payload[key] === false)
      formData.append(key, payload[key])
  }

  const userService = new UsersService()
  await userService.updateUser(payload.id, formData)

  context.commit(types.UPDATE_USER, payload)
}
