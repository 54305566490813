import { ActionContext } from 'vuex'

import { getAbVariant } from '@/common/helpers/utils'

import NucleusService from '@/common/services/nucleus'

import { RootState, SignUpState, User } from '@/store/interfaces'

import * as types from './mutations-types'

export const setCurrentStep = async (
  context: ActionContext<SignUpState, RootState>,
  currentStep: number
) => {
  context.commit(types.SET_CURRENT_STEP, currentStep)
}

export const setUser = async (
  context: ActionContext<SignUpState, RootState>,
  user: User
) => {
  const updatedUser = { ...context.state.user, ...user }
  context.commit(types.SET_USER, updatedUser)
}
export const setTenant = async (
  context: ActionContext<SignUpState, RootState>,
  tenant: string
) => {
  context.commit(types.SET_TENANT, tenant)
}

export const setUsername = async (
  context: ActionContext<SignUpState, RootState>,
  username: string
) => {
  context.commit(types.SET_USERNAME, username)
}
export const setCompanySize = async (
  context: ActionContext<SignUpState, RootState>,
  companySize: string
) => {
  context.commit(types.SET_COMPANY_SIZE, companySize)
}
export const setCompanySite = async (
  context: ActionContext<SignUpState, RootState>,
  companySite: string
) => {
  context.commit(types.SET_COMPANY_SITE, companySite)
}
export const setPhone = async (
  context: ActionContext<SignUpState, RootState>,
  phone: string
) => {
  context.commit(types.SET_PHONE, phone)
}
export const setFormattedPhone = async (
  context: ActionContext<SignUpState, RootState>,
  formattedPhone: string
) => {
  context.commit(types.SET_FORMATTED_PHONE, formattedPhone)
}

export const setVariant = (
  context: ActionContext<SignUpState, RootState>,
  variantPercentage = 0.5
) => {
  const variant = getAbVariant(variantPercentage)
  context.commit(types.SET_VARIANT, variant)
}

export const sendPhoneCodeToUser = async (
  context: ActionContext<SignUpState, RootState>
) => {
  const payload = {
    number: context.state.user!.phone,
    name: context.state.user!.name,
    email: context.state.user!.email
  }
  const nucleusService = new NucleusService()
  await nucleusService.sendVerificationPhoneCode(payload)
}

export const verifyPhoneCode = async (
  context: ActionContext<SignUpState, RootState>,
  phoneCode: string
) => {
  const nucleusService = new NucleusService()
  return await nucleusService.verifyPhoneCode(
    context.state.user!.phone,
    phoneCode
  )
}
