import { ActionContext } from 'vuex'

import Storage from '@octadesk-tech/storage'

import { Filters, PersonTypes } from '@/common/helpers/enums'
import { OperatorTypes } from '@/common/helpers/enums/operator-types.enum'
import { EntityPersonTypes } from '@/common/helpers/enums/person-types'
import { ExportPayload, Query } from '@/common/helpers/interfaces/query'

import { ChatService, ChatAgentsService } from '@/common/services'
import PersonsService from '@/common/services/persons'

import {
  RootState,
  ContactState,
  FilterContact,
  PayloadContact,
  PayloadToggle,
  Contact,
  IUpdateContactsProperties
} from '@/store/interfaces'

import * as types from './mutations-types'

export const getContacts = async (
  context: ActionContext<ContactState, RootState>
) => {
  const personService = new PersonsService()
  const contacts = await personService.getPersons()
  context.commit(types.SET_CONTACTS, contacts)
}

export const getAgents = async (
  context: ActionContext<ContactState, RootState>
) => {
  const chatService = new ChatService()
  const agents = await chatService.getAgents()
  context.commit(types.SET_AGENTS, agents)
}

export const getAgentsByGroupId = async (
  context: ActionContext<ContactState, RootState>,
  groupId: string
) => {
  const chatAgentsService = new ChatAgentsService()
  const agents = await chatAgentsService.getAgentsByGroupId(groupId)
  context.commit(types.SET_GROUP_AGENTS, agents)
}

export const unsetGroupAgents = (
  context: ActionContext<ContactState, RootState>
) => {
  context.commit(types.SET_GROUP_AGENTS, undefined)
}

export const getContact = async (
  context: ActionContext<ContactState, RootState>,
  id: string
) => {
  const personService = new PersonsService()
  const contact = await personService.getContact(id)
  context.commit(types.SET_CONTACT, contact)
}

export const cleanContact = (context: ActionContext<ContactState, RootState>) =>
  context.commit(types.SET_CONTACT, {})

export const getPaginatedContacts = (
  context: ActionContext<ContactState, RootState>,
  filter: FilterContact
) => {
  const personService = new PersonsService()

  context.commit(types.FETCHING_PAGINATED_CONTACTS, true)
  personService
    .getPaginatedContacts(filter)
    .then(contacts => {
      context.commit(types.SET_CONTACTS, contacts.items)
      context.commit(types.SET_CONTACTS_COUNT, contacts.total)
    })
    .finally(() => {
      context.commit(types.FETCHING_PAGINATED_CONTACTS, false)
    })
}

export const loadMoreContacts = async (
  context: ActionContext<ContactState, RootState>,
  filter: FilterContact
) => {
  const personService = new PersonsService()
  const contacts = await personService.getPaginatedContacts(filter)
  context.commit(types.APPEND_CONTACTS, contacts.items)
}

export const createContact = async (
  context: ActionContext<ContactState, RootState>,
  payload: PayloadContact
) => {
  const personService = new PersonsService()
  await personService.createContact({
    ...payload.contact
  })
  const counter = payload.counter + 1
  context.commit(types.SET_CONTACTS_COUNT, counter)
}

export const updateContact = async (
  context: ActionContext<ContactState, RootState>,
  payload: Contact
) => {
  delete payload.othersCustomerCode
  const chatService = new ChatService()
  await chatService.updateCustomer(payload)
  context.commit(types.UPDATE_CONTACT, payload)
}

export const toggleContact = async (
  context: ActionContext<ContactState, RootState>,
  payload: PayloadToggle
) => {
  const personService = new PersonsService()
  await personService.toggleContact(payload.id, payload.toggle)
  context.commit(types.TOGGLE_CONTACT, payload)
}

export const setBulkContactAction = async (
  context: ActionContext<ContactState, RootState>,
  payload: {
    action?: string
    idsPerson?: Array<string>
    properties: {
      responsible: {
        id: string
        name: string
      }
    }
  }
) => {
  const personService = new PersonsService()

  await personService.toggleContacts(payload).then(() => {
    const { contacts } = context.getters

    const ids = payload?.idsPerson || []

    if (ids.length) {
      const updatedContacts = contacts.map((contact: any) => {
        if (ids.includes(contact.id)) {
          return {
            ...contact,
            ...payload.properties
          }
        }

        return contact
      })

      context.commit(types.SET_CONTACTS, updatedContacts)
    } else if (payload?.action === 'includesAll') {
      context.commit(
        types.SET_CONTACTS,
        contacts.map((contact: any) => {
          return {
            ...contact,
            ...payload.properties
          }
        })
      )
    }
  })
}

export const setBulkContactResponsible = async (
  context: ActionContext<ContactState, RootState>,
  payload: {
    idsPerson: Array<string>
    properties: {
      responsible: {
        id: string
        name: string
      }
    }
  }
) => {
  const personService = new PersonsService()

  const { contacts } = context.getters

  const {
    idsPerson,
    properties: { responsible }
  } = payload

  const updatedContacts = contacts.map((contact: any) => {
    if (idsPerson.includes(contact.id)) {
      return {
        ...contact,
        responsible
      }
    }

    return contact
  })

  await personService.toggleContacts(payload)

  context.commit(types.SET_CONTACTS, updatedContacts)
}

export const countContacts = async (
  context: ActionContext<ContactState, RootState>,
  filter: FilterContact
) => {
  const personService = new PersonsService()
  const result = await personService.countFilteredContacts(filter)
  context.commit(types.SET_CONTACTS_COUNT, result)
}

const customFieldsResolver = (customFields: any, exportType?: string) => {
  const { customFields: personCustomFields, organizationCustomFields } =
    customFields

  if (!exportType || exportType === EntityPersonTypes.PERSON) {
    return personCustomFields
  }
  if (exportType === EntityPersonTypes.ORGANIZATION) {
    return organizationCustomFields
  }
}

interface ExternalQueriesResolver {
  hasTextSearch?: Query
  textSearchValue?: PersonTypes
  externalQueries: Query[]
}

const externalQueriesResolver = (
  queries: any,
  exportType?: string
): ExternalQueriesResolver => {
  let externalQueries: Query[] = []

  if (!exportType || exportType === EntityPersonTypes.PERSON) {
    externalQueries.push({
      propertyName: 'Type',
      operator: OperatorTypes.EQUALS,
      valueCompare: PersonTypes.Customer
    })
  }

  if (queries.length) {
    externalQueries.push(...queries)
  }

  const hasTextSearch = externalQueries.find(
    query => query.propertyName === Filters.TEXT && query.valueCompare
  )

  if (hasTextSearch && exportType === EntityPersonTypes.ORGANIZATION) {
    externalQueries = externalQueries.filter(
      query => query.propertyName !== Filters.TEXT
    )
  }
  const textSearchValue = hasTextSearch?.valueCompare

  return { externalQueries, textSearchValue, hasTextSearch }
}

export const getContactStatus = async (
  context: ActionContext<ContactState, RootState>
) => {
  const personService = new PersonsService()
  const storageKey = `fixedPerson.contactStatus`
  let contactStatusArray = Storage.getItem(storageKey)

  if (contactStatusArray == null) {
    contactStatusArray = await personService.getContactStatus()
    Storage.setItem(storageKey, contactStatusArray)
  }

  context.commit(types.SET_CONTACT_STATUS_LIST, contactStatusArray)
}

export const enqueueCustomerExport = async (
  context: ActionContext<any, any>,
  payload: ExportPayload
) => {
  const personService = new PersonsService()

  const customFieldsPath = context.rootState?.customFields
  const customFields = customFieldsResolver(
    customFieldsPath,
    payload.exportType
  )

  const { externalQueries, hasTextSearch, textSearchValue } =
    externalQueriesResolver(payload.queries, payload.exportType)

  const columns = customFields.map((cf: any) => ({
    fieldId: `CustomField.${cf.fieldId}`,
    label: cf.title
  }))

  return personService.enqueuePersonExport({
    Filter: {
      Take: 50,
      PropertySort: 'Name',
      SortDirection: 'asc',
      includeExcludedRecords: true,
      externalQueries,
      Skip: 0,
      TextSearch: !!hasTextSearch,
      Search: textSearchValue ?? null
    },
    Columns: columns || [],
    ExportType: payload.exportType || null
  })
}

export const getSubDomainTotalContacts = async (
  context: ActionContext<any, any>
) => {
  const personService = new PersonsService()

  const totalContacts = await personService.getTotalContacts()

  context.commit(types.SET_SUBDOMAIN_TOTAL_CONTACTS, totalContacts)
}

export const updateContactsProperties = (
  context: ActionContext<ContactState, RootState>,
  updateContactsProperties: IUpdateContactsProperties
) => context.commit(types.UPDATE_CONTACTS_PROPERTIES, updateContactsProperties)
