import {
  CommerceOnboardingSteps,
  CompanyCommerceTypes,
  AvailableTools
} from '@/common/helpers/enums/commerce-onboarding'

import {
  CommerceOnboardingState,
  CommerceOnboardingAvailableSectors,
  CommerceOnboardingAvailableTools,
  AboutCompany
} from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_COMMERCE_ONBOARDING_INITIAL_DATA_LOADER](
    state: CommerceOnboardingState,
    initialDataLoader: boolean
  ) {
    state.initialDataLoader = initialDataLoader
  },
  [types.SET_COMMERCE_ONBOARDING_STEP](
    state: CommerceOnboardingState,
    step: CommerceOnboardingSteps
  ) {
    state.currentOnboardingStep = step
  },
  [types.SET_COMMERCE_ONBOARDING_AVAILABLE_SECTORS](
    state: CommerceOnboardingState,
    sectors: CommerceOnboardingAvailableSectors[]
  ) {
    state.selectsOptions.availableSectors = sectors
  },
  [types.SET_COMMERCE_ONBOARDING_AVAILABLE_TOOLS](
    state: CommerceOnboardingState,
    availableTools: CommerceOnboardingAvailableTools[]
  ) {
    state.selectsOptions.availableTools = availableTools
  },
  [types.SET_COMMERCE_ONBOARDING_COMPANY_SECTOR_ID](
    state: CommerceOnboardingState,
    companySectorId: string
  ) {
    state.companySectorId = companySectorId
  },
  [types.SET_COMMERCE_ONBOARDING_COMPANY_COMMERCE_TYPE](
    state: CommerceOnboardingState,
    companyCommerceType: CompanyCommerceTypes
  ) {
    state.companyCommerceType = companyCommerceType
  },
  [types.SET_COMMERCE_ONBOARDING_ABOUT_COMPANY](
    state: CommerceOnboardingState,
    aboutCompany: AboutCompany
  ) {
    state.aboutCompany = aboutCompany
  },
  [types.SET_COMMERCE_ONBOARDING_COMPANY_TOOLS_IDS](
    state: CommerceOnboardingState,
    toolsIds: Array<AvailableTools>
  ) {
    state.companyToolsIds = toolsIds
  },
  [types.SET_COMMERCE_OTHER_TOOLS](
    state: CommerceOnboardingState,
    otherTools: string
  ) {
    state.otherTools = otherTools
  }
}
