import { IntegrationTypes } from '@/modules/Chat/components/conversation/enums'

export const commerceIntegrationsLoader = ({ commerce }) =>
  commerce.integrations.loader

export const commerceSelectedIntegrationId = ({ commerce }) =>
  commerce.integrations.selectedIntegrationId

export const commerceIntegrations = ({ commerce }) =>
  commerce.integrations.items

export const commerceHasAvailableIntegrations = ({ commerce }) =>
  !!commerce.integrations.items.filter(
    integration => integration.name !== IntegrationTypes.magento
  ).length
