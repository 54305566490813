import dayjs from 'dayjs'

import { cookieHelper } from '@/modules/Chat/helpers'

import store from '@/store'

let initialized = false

const hasMixpanel = () =>
  typeof window !== 'undefined' && typeof window.mixpanel !== 'undefined'

const translateRoleType = roleType => {
  let role
  switch (roleType) {
    case 1:
      role = 'Owner'
      break
    case 2:
      role = 'Admin'
      break
    case 4:
      role = 'Agent'
      break
    default:
      role = 'Other'
      break
  }

  return role
}

const handleInitialization = () => {
  const userLogged = store.state.userLogged
  const companyModel = store.state.companyModel
  const subDomainStatus = store.state.subDomainStatus

  if (userLogged && companyModel && subDomainStatus) {
    const planType = subDomainStatus.isTrial ? 'Trial' : 'Paid'
    const companySubDomain = userLogged.subDomain
    const roleType = translateRoleType(userLogged.roleType)
    const companyCreationDate = companyModel.dateCreation
      ? dayjs(companyModel.dateCreation).format('DD/MM/YYYY')
      : 'not-provided'

    const people = {
      $name: userLogged.name,
      $email: userLogged.email,
      'Plan Type': planType,
      'Company Id': companySubDomain,
      'Role Type': roleType,
      'Subdomain Create Date': companyCreationDate
    }

    const superProperties = {
      'Plan Type': planType,
      'Role Type': roleType,
      'Company Id': companySubDomain
    }

    if (cookieHelper.getCookie('octa-ssid')) {
      identify(cookieHelper.getCookie('octa-ssid'))
    } else {
      identify(userLogged.email)
    }
    setPeople(people)
    register(superProperties)

    initialized = true
  }
}

const track = (eventName, eventProperties) => {
  if (hasMixpanel()) {
    if (!initialized) {
      handleInitialization()
    }
    window.mixpanel.track(eventName, eventProperties)
  }
}

const identify = identifier => {
  if (hasMixpanel()) {
    window.mixpanel.identify(identifier)
  }
}

const setPeople = people => {
  if (hasMixpanel()) {
    window.mixpanel.people.set(people)
  }
}

const register = properties => {
  if (hasMixpanel()) {
    window.mixpanel.register(properties)
  }
}

export const mixpanel = {
  setPeople,
  register,
  track,
  identify
}
