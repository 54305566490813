import axios from 'axios'

import { headers } from '@octadesk-tech/services'

import { getChatClient } from '@/modules/Chat/services/http'

const CancelToken = axios.CancelToken

let fetchChatsCancelToken

export const fetchChats = ({ status, page, filter }) => {
  if (fetchChatsCancelToken) {
    fetchChatsCancelToken.cancel()
  }

  fetchChatsCancelToken = CancelToken.source()

  const query = {
    status,
    page,
    ...filter
  }

  return getChatClient()
    .then(client =>
      client.post('rooms/list', query, {
        cancelToken: fetchChatsCancelToken.token,
        ...headers.getAuthorizedHeaders()
      })
    )
    .then(res => res.data)
    .catch(error => {
      if (axios.isCancel(error))
        console.warn('Request canceled in fetchChats', error.message)
      else {
        console.error('[ERROR] in fetchChats:', error)
        throw error
      }
    })
}

export const getChannelsUsage = () => {
  const { headers: requestHeaders } = headers.getAuthorizedHeaders()

  return getChatClient()
    .then(client =>
      client.get('rooms/channels/usage', {
        params: {
          sd: requestHeaders.AppSubDomain
        },
        headers: requestHeaders
      })
    )
    .then(res => res.data)
}

export const fetchResponseServiceTimeoutCount = async () => {
  const client = await getChatClient()
  const response = await client.get('rooms/timeout/count', {
    headers: headers.getAuthorizedHeaders().headers
  })

  return response.data
}
