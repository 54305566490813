import { OrderErrorTypes } from '@/modules/Chat/components/conversation/enums'
import {
  getCommerceClientOrdersService,
  getCommerceClientOrderProductsService,
  createCommerceClientOrderService
} from '@/modules/Chat/components/conversation/services/commerce-orders'
import * as types from '@/modules/Chat/components/conversation/store/mutations-types/commerce-orders'

export const getCommerceClientOrders = async (
  context,
  { cellphone, email }
) => {
  context.commit(types.SET_COMMERCE_CLIENT_ORDERS_LOADER, true)

  const { commerceSelectedIntegrationId: integrationId } = context.getters

  context.commit(types.SET_COMMERCE_CLIENT_ORDERS, [])

  let phone = cellphone

  const isBrazilNumber = cellphone.startsWith('+55')

  if (isBrazilNumber) {
    phone = cellphone.replace(/^([+]\d{2})(\d{2})9?(\d{8})$/, '$29$3')
  }

  try {
    const orders = await getCommerceClientOrdersService({
      integrationId,
      cellphone: phone,
      email
    })

    context.commit(types.SET_COMMERCE_CLIENT_ORDERS, orders)

    context.dispatch('setCommerceClientOrdersNotFound', false)
  } catch (err) {
    context.dispatch(
      'setCommerceClientOrdersNotFound',
      err.message === OrderErrorTypes.notFound
    )
  }

  context.commit(types.SET_COMMERCE_CLIENT_ORDERS_LOADER, false)
}

export const getCommerceClientOrderProducts = async (context, orderId) => {
  context.commit(types.SET_COMMERCE_CLIENT_ORDER_PRODUCTS_LOADER, true)

  const { commerceSelectedIntegrationId: integrationId, commerceClientOrders } =
    context.getters

  const orderHasProducts = commerceClientOrders.find(
    order =>
      order.id === orderId &&
      Object.prototype.hasOwnProperty.call(order, 'products')
  )

  let updatedOrder

  if (!orderHasProducts) {
    updatedOrder = await getCommerceClientOrderProductsService({
      integrationId,
      orderId
    })
  } else {
    updatedOrder = orderHasProducts
  }

  const orders = commerceClientOrders.map(order => {
    if (order.id === orderId) {
      return {
        ...order,
        ...updatedOrder
      }
    }

    return order
  })

  context.commit(types.SET_COMMERCE_CLIENT_ORDERS, orders)

  context.commit(types.SET_COMMERCE_CLIENT_ORDER_PRODUCTS_LOADER, false)
}

export const setCommerceClientOrdersNotFound = (context, isNotFound) => {
  context.commit(types.SET_COMMERCE_CLIENT_ORDERS_NOT_FOUND, isNotFound)
}

export const createCommerceClientOrder = async (context, payload) => {
  const order = await createCommerceClientOrderService(payload)

  context.dispatch('setCommerceClientOrderPaymentUrl', order.paymentUrl)
}

export const setCommerceClientOrderPaymentUrl = (context, paymentUrl) => {
  context.commit(types.SET_COMMERCE_CLIENT_ORDER_PAYMENT_URL, paymentUrl)
}
