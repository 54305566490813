import { isSafari } from '@/modules/Chat/helpers/detect-browser'

export const REGEX_EMOJI = '(?<!\\S)\\:([\\w+-]+?):(?!\\S)'
const SAFARI_REGEX_EMOJI = '\\:([\\w+-]+?):'
export const LEGACY_EMOJI_LIST = [
  ':\\)',
  '\\(:',
  ':-\\)',
  ':\\|',
  ':-\\|',
  ':-o',
  ':o\\)',
  ':o',
  ':-O',
  ':O',
  ':p',
  ':-p',
  ':P',
  ':-P',
  ':b',
  ':-b',
  ':\\\\',
  ':-\\\\',
  ':\\/',
  ':-\\/',
  '\\):',
  ':\\(',
  ':-\\(',
  '>:\\(',
  '>:-\\(',
  'C:',
  'c:',
  ':D',
  ':-D',
  ':>',
  ':->',
  ':\\*',
  ':-\\*',
  ":'\\(",
  ':8',
  ';-p',
  ';b',
  ';p',
  ';-b',
  '<3',
  'D:',
  '8\\)',
  '<\\/3',
  '=\\)',
  '=-\\)',
  ';P',
  ';\\)',
  ';-\\)',
  ';-P'
]

const resolveRegexBasedOnBrowser = () => {
  if (isSafari) {
    return RegExp(
      `${SAFARI_REGEX_EMOJI}|\\s(${LEGACY_EMOJI_LIST.join('|')})`,
      'g'
    )
  }

  return RegExp(
    `${REGEX_EMOJI}|(?<!\\S)(${LEGACY_EMOJI_LIST.join('|')})(?!\\S)`,
    'g'
  )
}

export const REGEX_TEXT_TO_EMOJI = resolveRegexBasedOnBrowser()
