import store from '@/store'

import privateRouter from '@/routes/router/private-router'
export default notification => {
  const chatKey = privateRouter.history.current.params.key
  const hasNewAreaNotificationsEnabled =
    store.getters.hasNewAreaNotificationsEnabled
  return (
    hasNewAreaNotificationsEnabled &&
    notification.data.roomKey &&
    chatKey !== notification.data.roomKey
  )
}
