import Vue from 'vue'

import {
  arrangeMessage,
  arrangeChat
} from '@/modules/Chat/components/conversation/services/chat-arranger'
import * as types from '@/modules/Chat/components/conversation/store/mutations-types'

import commerce from './commerce'
import commerceIntegrations from './commerce-integrations'
import commerceOrders from './commerce-orders'
import ticket from './ticket'

const updateSessionMessage = (state, message) => {
  const messages = state.messages.filter(
    m => m.key !== message.key && m.key !== message.replacedKey
  )

  arrangeMessage(message)

  messages.push(message)

  state.messages = messages
}

const conversationMutations = {
  [types.SET_CHAT](state, chat) {
    state.messages = []

    state.chat = arrangeChat(chat)

    state.message.attachments = []
  },
  [types.CLEAN_CHAT](state) {
    state.messages = []

    state.chat = undefined

    state.message.attachments = []
  },
  [types.SET_CHAT_KEY](state, chatKey) {
    state.chatEmptyState = false

    state.chat = undefined

    state.message.attachments = []

    state.chatKey = chatKey
  },
  [types.UPDATE_CHAT](state, chat) {
    if (state.chat?.permissions && chat)
      chat.permissions = state.chat.permissions
    const updatedChat = arrangeChat(chat, state.chat)

    state.chat = updatedChat
  },
  [types.SET_CHAT_HISTORY](state, chatHistory) {
    state.chatHistory = chatHistory
    if (!state.chat) {
      state.chat = chatHistory
    }
  },
  [types.SET_MESSAGE_TYPE](state, type) {
    state.message.type = type
  },
  [types.SET_MESSAGE_TAB](state, tab) {
    state.message.tab = tab
  },
  [types.ADD_ATTACHMENT](state, attachment) {
    state.message.attachments.push(attachment)
  },
  [types.REMOVE_ATTACHMENT](state, attachment) {
    state.message.attachments = state.message.attachments.filter(
      a => a.url !== attachment.url
    )
  },
  [types.CLEAR_ATTACHMENTS](state) {
    state.message.attachments = []
  },
  [types.START_TYPING](state) {
    state.typing = true
  },
  [types.STOP_TYPING](state) {
    state.typing = false
  },
  [types.TYPING_USER](state, user) {
    state.typingUser = user
  },
  [types.CREATE_MESSAGE](state, message) {
    state.messages.push(message)
  },
  [types.SET_MESSAGE_SENDING](state, message) {
    message.status = 'sending'

    updateSessionMessage(state, message)
  },
  [types.SET_MESSAGE_ERROR](state, value) {
    const message = value.message

    const errorCustomCode = value.customCode

    message.status = 'error'

    if (errorCustomCode) {
      message.errorCustomCode = errorCustomCode
    }

    updateSessionMessage(state, message)
  },
  [types.SET_MESSAGE_SENDED](state, message) {
    updateSessionMessage(state, message)
  },
  [types.MACRO_CREATION_MODAL](state, payload) {
    state.showMacroCreationModal = payload
  },
  [types.MACRO_EDIT_MODAL](state, payload) {
    state.showMacroEditModal = payload
  },
  [types.SET_MACRO_CONFIRM_DELETE_MODAL](state, payload) {
    state.confirmMacroDeleteModal = payload
  },
  [types.SET_SHOW_VARIABLES_MODAL](state, isActive) {
    state.showVariablesModal = isActive
  },
  [types.SET_CATEGORY_CHANGE_MODAL](state, payload) {
    state.categoryChangeModal = payload
  },
  [types.SET_MACROS](state, macros) {
    state.macros = macros
  },
  [types.CREATE_MACRO](state, payload) {
    state.macros.push(payload)
  },
  [types.UPDATE_MACRO](state, payload) {
    const macroIndex = state.macros.findIndex(m => m.id === payload.id)

    if (macroIndex !== -1) {
      const updatedMacros = state.macros

      updatedMacros[macroIndex] = { ...payload }

      state.macros = [...updatedMacros]
    }
  },
  [types.REMOVE_MACRO](state, id) {
    state.macros = state.macros.filter(m => m.id !== id)
  },
  [types.SELECT_MACRO](state, payload) {
    state.selectedMacro = payload
  },
  [types.STORE_UNSENDEND_COMMENT](state, { roomId, message }) {
    state.unsendedComments[roomId] = {
      public: message.public,
      internal: message.internal,
      mentions: message.mentions,
      header: message.header
    }
  },
  [types.REMOVE_UNSENDEND_COMMENT](state, roomKey) {
    delete state.unsendedComments[roomKey]
  },
  [types.STORE_UNSENDEND_QUOTEDMESSAGE](
    state,
    { roomId, message, messageType }
  ) {
    state.unsendedQuotedMessages[roomId] =
      state.unsendedQuotedMessages[roomId] || {}

    state.unsendedQuotedMessages[roomId][messageType] = message

    state.unsendedQuotedMessages = { ...state.unsendedQuotedMessages }
  },
  [types.REMOVE_UNSENDEND_QUOTEDMESSAGE](state, { roomId, messageType }) {
    if (
      state.unsendedQuotedMessages[roomId] &&
      state.unsendedQuotedMessages[roomId][messageType]
    ) {
      delete state.unsendedQuotedMessages[roomId][messageType]

      if (!Object.keys(state.unsendedQuotedMessages[roomId]).length)
        delete state.unsendedQuotedMessages[roomId]

      state.unsendedQuotedMessages = { ...state.unsendedQuotedMessages }
    }
  },
  [types.QUOTE_MESSAGE](state, message) {
    state.unsendedQuoteMessage = message
  },
  [types.CANCEL_QUOTE_MESSAGE](state) {
    state.unsendedQuoteMessage = undefined
  },
  [types.SET_MENTIONED_MESSAGE_KEY](state, key) {
    state.mentionedMessageKey = key
  },
  [types.SET_LIST_MENTIONED_MESSAGE_KEY](state, key) {
    state.listMentionedMessageKey = key
  },
  [types.SET_FOCUSED_MESSAGE_KEY](state, msgKey) {
    state.focusedMessageKey = msgKey
  },
  [types.CLEAR_FOCUSED_MESSAGE_KEY](state) {
    state.focusedMessageKey = undefined
  },
  // conversation scroll
  [types.SET_CONVERSATION_PAGE](state, page) {
    state.conversationPage = page
  },
  [types.SET_SCROLL_BOTTOM](state, payload) {
    state.scrollToBottomFlag = payload
  },
  [types.SET_IS_TAGS_POPOVER_OPEN](state, value) {
    state.isTagsPopoverOpen = value
  },
  [types.SET_CURRENT_CHAT_TAGS](state, value) {
    state.chatTags = value
  },
  [types.SET_CURRENT_INTEGRATOR](state, integrator) {
    state.currentIntegrator = integrator
  },
  [types.SET_SEND_WINDOW](state, open) {
    state.sendWindowOpen = open
  },
  [types.SET_INTEGRATOR_MESSAGE_ERROR](state, messageType) {
    state.integratorMessageError = messageType
  },
  [types.SET_IS_ADD_CONTACT_MODAL_OPEN](state, value) {
    state.isAddContactModalOpen = value
  },
  [types.SET_SELECTED_CONTACT_TO_ADD](state, value) {
    state.selectedContactToAdd = value
  },
  [types.SET_EXISTING_CONTACTS](state, value) {
    state.existingContacts = value
  },
  [types.SET_ADD_CONTACT_MODAL_STEP](state, value) {
    state.addContactModalStep = value
  },
  [types.SET_ADD_CONTACT_LAST_PAYLOAD](state, value) {
    state.addContactLastPayload = value
  },
  [types.SET_UPDATE_CONTACT_LAST_PAYLOAD](state, value) {
    state.updateContactLastPayload = value
  },
  [types.SET_SELECTED_CONTACT_TO_UPDATE](state, value) {
    state.selectedContactToUpdate = value
  },
  [types.SET_SELECTED_TEMPLATE_MESSAGE](state, value) {
    state.selectedTemplateMessage = value
  },
  [types.SET_REF_AUTO_FOCUS](state, ref) {
    state.refAutoFocus = ref
  },
  [types.SET_UPDATED_TAGS_MAP](state, tags) {
    const updatedTagsMap = { ...state.updatedTagsMap }

    tags.forEach(tag => {
      updatedTagsMap[tag.id] = { ...tag }
    })

    state.updatedTagsMap = updatedTagsMap
  },
  [types.SET_REMOVED_UPDATED_TAGS_MAP](state, tags) {
    const updatedTagsMap = { ...state.updatedTagsMap }

    tags.forEach(tag => {
      delete updatedTagsMap[tag]
    })

    state.updatedTagsMap = updatedTagsMap
  },
  [types.SET_AUTOCOMPLETE_PUBLIC_TAGS](state, tags) {
    state.autocompletePublicTags = tags
  },
  [types.SET_SHOW_CONVERSATION_ASSUME_ERROR_MODAL](state, show) {
    state.showConversationAssumeErrorModal = show
  },
  [types.OPEN_ATTACHMENT_FULLSCREEN](state) {
    state.showAttachmentFullscreen = true
  },
  [types.CLOSE_ATTACHMENT_FULLSCREEN](state) {
    state.showAttachmentFullscreen = false
  },
  [types.SET_ATTACHMENT_FULLSCREEN_INDEX](state, index) {
    state.attachmentFullscreenInitialIndexState = index
  },
  /**
   * Person
   */
  [types.SET_PERSON](state, person) {
    state.person = person
  },
  [types.UPDATE_PERSON_PROPERTY](state, props) {
    const propKeys = Object.keys(props)
    if (!propKeys.length)
      throw new Error(
        `Missing parameter 'props' in '${types.UPDATE_PERSON_PROPERTY}'`
      )

    propKeys.forEach(key => Vue.set(state.person, key, props[key]))
  },
  [types.UPDATE_CURRENT_CHAT_PROPERTY](state, { chatKey, props }) {
    const propKeys = Object.keys(props)
    if (!propKeys.length)
      throw new Error(
        `Missing parameter 'props' in '${types.UPDATE_CURRENT_CHAT_PROPERTY}'`
      )

    if (!state.chat || state.chat.key !== chatKey) return

    propKeys.forEach(key => Vue.set(state.chat, key, props[key]))
  },
  /**
   * Custom Fields
   */
  [types.SET_CUSTOM_FIELDS](state, customFields) {
    state.customFieldsData = customFields
  },
  [types.SET_ORGANIZATIONS_CUSTOM_FIELDS](state, customFields) {
    state.organizationsCustomFieldsData = customFields
  },
  [types.SET_SELECT_ORGANIZATION_OPTIONS](state, options) {
    state.selectOrganizationOptions = options
  },
  [types.SET_SELECTED_ORGANIZATION](state, organization) {
    state.selectedOrganization = organization
  },
  [types.SET_CURRENT_CHAT_PAGINATION](state, currentChatPagination) {
    state.currentChatPagination = currentChatPagination
  },
  [types.SET_CURRENT_CHAT_MESSAGES_PAGE](state, currentChatMessagesPage) {
    state.currentChatPagination.page = currentChatMessagesPage
  },
  [types.SET_CURRENT_CHAT_PAGES_LOADED](state, currentChatPagesLoaded) {
    if (state.currentChatPagination.pagesLoaded === undefined) {
      state.currentChatPagination.pagesLoaded = []
    }
    state.currentChatPagination.pagesLoaded.push(currentChatPagesLoaded)
  },
  [types.SET_CURRENT_CHAT_NEW_PAGE_LOADING](state, isLoading) {
    state.currentChatNewPageLoading = isLoading
  },
  [types.SET_DATE_TO_UPDATE_LAST_MESSAGE_TIMER](
    state,
    dateToUpdateLastMessageTimer
  ) {
    state.dateToUpdateLastMessageTimer = dateToUpdateLastMessageTimer
  },
  [types.SET_BUILDING_CHAT_MESSAGE_LIST](state, buildingChatMessageList) {
    state.buildingChatMessageList = buildingChatMessageList
  },
  [types.SET_CONTACT_STATUS_LIST](state, list) {
    state.contactStatusList = list
  },
  [types.SET_DEFAULT_EMAIL_ADDRESS](state, defaultEmailAddress) {
    state.defaultEmailAddress = defaultEmailAddress
  },
  [types.SET_CURRENT_CHAT_ASIDE](state, payload) {
    state.currentChatAside = payload
  },
  [types.SET_CHAT_AI_MODAL_PROPS](state, payload) {
    state.chatAi = payload
  },
  [types.SET_DROPDOWN_READY_MESSAGES_OPEN](state, isOpen) {
    state.isDropdownReadyMessagesOpen = isOpen
  }
}

export default {
  ...conversationMutations,
  ...commerce,
  ...commerceOrders,
  ...commerceIntegrations,
  ...ticket
}
