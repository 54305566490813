import { ChatStatus } from '@/common/helpers/enums'

export default (limit: number, userLoggedId: string, isAdmin: boolean) => ({
  ...(!isAdmin && {
    'agent._id': {
      $in: [userLoggedId, null]
    }
  }),
  status: [ChatStatus.WAITING, ChatStatus.TALKING, ChatStatus.MISSED],
  limit,
  lastMessageTimeExceeded: true
})
