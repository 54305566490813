import { Agent } from '@/common/helpers/interfaces'

import { ChatAgentsService } from '@/common/services'

import { DataSourceResolver } from './interfaces'

let service: ChatAgentsService
const getService = () => {
  if (service) {
    return service
  }
  service = new ChatAgentsService()
  return service
}

export const agents: DataSourceResolver = searchValue =>
  getService()
    .getAgents()
    .then((agents: Agent[]) =>
      agents
        .filter(
          agent =>
            agent.name.includes(searchValue as string) ||
            agent.email?.includes(searchValue as string)
        )
        .map(agent => ({
          id: agent.id as string,
          name: `${agent.name} (${agent.email})`
        }))
    )
