import { AxiosInstance } from 'axios'
import qs from 'qs'

import { services } from '@octadesk-tech/services'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import { CreditPackage, CreditPackageResponse, Pagination } from '../interfaces'
import { FilterKeys } from './credits.enum'

export class CreditsService {
  private static instance: CreditsService

  _client: any

  public static getInstance(): CreditsService {
    if (!this.instance) {
      this.instance = new CreditsService()
    }

    return this.instance
  }

  private getClient(): Promise<AxiosInstance> {
    return getClient('creditManagement')
  }

  async getChatIntegratorClient() {
    if (this._client) {
      return this._client
    }

    // eslint-disable-next-line
    if (!services['chatIntegrator']) {
      throw new Error('Cannot get http client: missing service chatIntegrator')
    }

    // eslint-disable-next-line
    this._client = await services['chatIntegrator'].getClient(legacyHeaders())

    return this._client
  }

  async getProductExpenses({
    startDate,
    endDate
  }: {
    startDate: string
    endDate: string
  }) {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(
          `/product-expenses?startDate=${startDate}&endDate=${endDate}`,
          {
            ...legacyHeaders()
          }
        )
      )

      return data
    } catch (error) {
      console.error(error)

      throw new Error(`Error on getProductExpenses: ${error}`)
    }
  }

  async getConsumptionEstimation() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get('/consumption-estimation', {
          ...legacyHeaders()
        })
      )

      return data
    } catch (error) {
      console.error(error)

      throw new Error(`Error on getConsumptionEstimation: ${error}`)
    }
  }

  async getUseRecurringSubscriptions() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get('/specific-packages', {
          ...legacyHeaders()
        })
      )

      return data
    } catch (error) {
      console.error(error)

      throw new Error(`Error on getUseRecurringSubscriptions: ${error}`)
    }
  }

  async getCategorizedReport({
    product,
    phone,
    date
  }: {
    product: string
    phone: string
    date: {
      startDate: string
      endDate: string
    }
  }) {
    try {
      const { startDate, endDate } = date

      const query = {
        [FilterKeys.Product]: product,
        [FilterKeys.Phone]: phone,
        [FilterKeys.StartDate]: startDate,
        [FilterKeys.EndDate]: endDate
      }

      const { data } = await this.getClient().then(client =>
        client.get(`/categorized-report?${qs.stringify(query)}`, {
          ...legacyHeaders()
        })
      )

      return data
    } catch (error) {
      console.error(error)

      throw new Error(`Error on getCategorizedReport: ${error}`)
    }
  }

  async getIntegratorWhatsappNumbers() {
    try {
      const { data } = await this.getChatIntegratorClient().then(client =>
        client.get('/whatsapp/numbers', {
          ...legacyHeaders()
        })
      )

      return data
    } catch (ex) {
      console.error(ex)
    }
  }

  async getCreditPackages(
    { pageSize, page } = { pageSize: 5, page: 1 }
  ): Promise<CreditPackageResponse> {
    const emptyResponse = {
      results: [],
      pagination: {
        page: 1,
        pageSize: 1,
        total: 0,
        totalPages: 1,
        hasMore: false
      }
    }
    try {
      const { data } = await this.getClient().then(client =>
        client.get<{ results: CreditPackage[]; pagination: Pagination }>(
          `/all-packages?page=${page}&pageSize=${pageSize}`,
          {
            ...legacyHeaders()
          }
        )
      )
      return data ?? emptyResponse
    } catch (ex) {
      console.error(ex)
      throw new Error(`Error on getCreditPackages: ${ex}`)
    }
  }
}
