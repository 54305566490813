import { FieldTypes } from '@/common/helpers/enums/field-types.enum'

export const CustomFieldToComponent = {
  [FieldTypes.TEXT]: 'OctaTextField',
  [FieldTypes.TEXTAREA]: 'OctaTextField',
  [FieldTypes.SELECT]: 'OctaSelect',
  [FieldTypes.RADIO]: 'OctaSelect',
  [FieldTypes.NUMBER]: 'OctaTextField',
  [FieldTypes.DECIMAL]: 'OctaTextField',
  [FieldTypes.DATE]: 'OctaTextField'
}
