export const SET_COMMERCE_ONBOARDING_INITIAL_DATA_LOADER =
  'SET_COMMERCE_ONBOARDING_INITIAL_DATA_LOADER'

export const SET_COMMERCE_ONBOARDING_STEP = 'SET_COMMERCE_ONBOARDING_STEP'

export const SET_COMMERCE_ONBOARDING_AVAILABLE_SECTORS =
  'SET_COMMERCE_ONBOARDING_AVAILABLE_SECTORS'

export const SET_COMMERCE_ONBOARDING_AVAILABLE_TOOLS =
  'SET_COMMERCE_ONBOARDING_AVAILABLE_TOOLS'

export const SET_COMMERCE_ONBOARDING_COMPANY_SECTOR_ID =
  'SET_COMMERCE_ONBOARDING_COMPANY_SECTOR_ID'

export const SET_COMMERCE_ONBOARDING_COMPANY_COMMERCE_TYPE =
  'SET_COMMERCE_ONBOARDING_COMPANY_COMMERCE_TYPE'

export const SET_COMMERCE_ONBOARDING_ABOUT_COMPANY =
  'SET_COMMERCE_ONBOARDING_ABOUT_COMPANY'

export const SET_COMMERCE_ONBOARDING_COMPANY_TOOLS_IDS =
  'SET_COMMERCE_ONBOARDING_COMPANY_TOOLS_IDS'

export const SET_COMMERCE_OTHER_TOOLS = 'SET_COMMERCE_OTHER_TOOLS'
