import privateRouter from '@/routes/router/private-router'

const embedRoutes = [
  {
    area: 'ticket',
    action: 'edit',
    url: '/ticket/edit'
  },
  {
    area: 'report',
    action: 'view',
    url: '/ticket/report/view'
  },
  {
    area: 'report',
    action: 'list',
    url: '/ticket/report'
  },
  {
    area: 'trigger',
    action: 'edit',
    url: '/ticket/trigger/edit'
  },
  {
    area: 'trigger',
    action: 'list',
    url: '/ticket/trigger'
  },
  {
    area: 'smart-form',
    action: 'edit',
    url: '/ticket/smartform/edit'
  },
  {
    area: 'smart-form',
    action: 'list',
    url: '/ticket/smartform'
  },
  {
    area: 'macro',
    action: 'edit',
    url: '/ticket/macro/edit'
  },
  {
    area: 'macro',
    action: 'list',
    url: '/ticket/macro'
  },
  {
    area: 'custom-workflow',
    action: 'register',
    url: '/ticket/workflow/edit'
  },
  {
    area: 'custom-workflow',
    action: 'list',
    url: '/ticket/workflow'
  },
  {
    area: 'automation',
    action: 'edit',
    url: '/ticket/automation/edit'
  },
  {
    area: 'automation',
    action: 'list',
    url: '/ticket/automation'
  },
  {
    area: 'forms',
    action: 'edit',
    url: '/ticket/forms/edit'
  },
  {
    area: 'forms',
    action: 'list',
    url: '/ticket/forms'
  },
  {
    area: 'groups',
    action: 'profile',
    url: '/ticket/groups/profile'
  },
  {
    area: 'groups',
    action: 'list',
    url: '/ticket/groups'
  },
  {
    area: 'subject',
    action: 'edit',
    url: '/ticket/subject/edit'
  },
  {
    area: 'subject',
    action: 'list',
    url: '/ticket/subject'
  },
  {
    area: 'product',
    action: 'edit',
    url: '/ticket/product/edit'
  },
  {
    area: 'product',
    action: 'list-category',
    url: '/ticket/products'
  },
  {
    area: 'calendar',
    action: 'edit-calendar',
    url: '/ticket/calendar/edit-calendar'
  },
  {
    area: 'calendar',
    action: 'edit-holidays',
    url: '/ticket/calendar/edit-holidays'
  },
  {
    area: 'calendar',
    action: 'list',
    url: '/ticket/calendar'
  }
]

export const handleTicketRoutes = (event: MessageEvent) => {
  const { area, action, id: routeId } = event?.data?.routeData || {}
  embedRoutes.forEach(
    ({ area: routeArea, action: routeAction, url: routeUrl }) => {
      if (area === routeArea && action === routeAction) {
        if (routeId && routeAction !== 'list') {
          privateRouter.push(`${routeUrl}/${routeId}`)
        } else if (routeArea === 'calendar') {
          const calendarAdd = ['2', 'list-holidays'].includes(routeId)
            ? 'list-holidays'
            : ''
          const currentRoutePath = privateRouter.currentRoute.path
          if (currentRoutePath === `${routeUrl}/${calendarAdd}`) return
          privateRouter.push(`${routeUrl}/${calendarAdd}`)
        } else if (
          privateRouter.currentRoute.path !== routeUrl &&
          routeAction !== 'edit'
        ) {
          privateRouter.push(routeUrl)
        }
      }
    }
  )
}
