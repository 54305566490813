import { headers, services } from '@octadesk-tech/services'

import { Event } from '@/common/helpers/interfaces'

export default class AnalyticsService {
  async send(event: Event) {
    try {
      if (!event) {
        throw new Error('Error in analytics event: missing event data')
      }

      if (!event.anonymous_id) {
        console.error('Error in analytics event: missing anonymous_id')
      }

      await services.dataAnalytics
        .getClient()
        .post('/events', event, headers.getAuthorizedHeaders())
    } catch (ex) {
      console.error('Error at analytics send: ' + ex)
    }
  }
}
