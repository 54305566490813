import { ActionContext } from 'vuex'

import { WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE } from '@/modules/Woz/consts'
import {
  getDocuments,
  getDocument,
  updateActiveDocument,
  deleteDocument,
  bulkDelete,
  bulkActiveDocument,
  toggleKBDocuments,
  getAgentDocuments,
  linkContentToAgent,
  bulkProcessAgentDocuments,
  unlinkAgentDocuments,
  toggleLinkKBDocumentsAgent,
  updateActiveLinkDocumentAgent
} from '@/modules/Woz/content/services/ai-api'
import { DOCUMENT_STATUS, WOZ_MEMORY_SOURCE } from '@/modules/Woz/enums'

import i18n from '@/common/i18n'

import { RootState } from '@/store/interfaces'

import { TableWozDocument, WozContentState, WozDocument } from './interfaces'
import * as types from './mutation-types'

const PAGE_SIZE = 30

const getTableDocument = (document: any) => {
  return {
    ...document,
    sent: null,
    used: null,
    selected: false,
    childIsOpen: false,
    isChildVisible: false
  }
}

const getTableDocuments = (documents: WozDocument[], paginationInfo: any) => {
  const tableDocuments: TableWozDocument[] = []

  if (paginationInfo.kbItemsCount > 0 && paginationInfo.page === 1) {
    tableDocuments.push(
      getTableDocument({
        id: WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE,
        source: WOZ_MEMORY_SOURCE.KnowledgeBase,
        originalState: 'active',
        title: i18n?.t('woz.content.kb'),
        active: true,
        hasChild: true,
        childrenCount: paginationInfo.kbItemsCount,
        searching: false,
        currentPage: 1,
        totalPages:
          1 + Math.floor((paginationInfo.kbItemsCount - 1) / PAGE_SIZE)
      })
    )
  }
  const mappedDocuments = documents.map((doc: WozDocument) => {
    const state = doc.active ? 'active' : 'inactive'
    const originalState =
      doc.status === DOCUMENT_STATUS.COMPLETED ? state : 'processing'
    const document = getTableDocument({
      ...doc,
      originalState
    })

    if (document.source === WOZ_MEMORY_SOURCE.KnowledgeBase)
      document.parentId = WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE

    if (!document.parentId) tableDocuments.push(document)

    return document
  })

  mappedDocuments
    .filter((document: any) => document.parentId)
    .forEach((document: any) => {
      document.isChild = true
      const parent = tableDocuments.find(d => d.id === document.parentId)
      if (parent) {
        parent.hasChild = true
        parent.childIsOpen = false
        tableDocuments.splice(tableDocuments.indexOf(parent) + 1, 0, document)
      }
    })

  return tableDocuments
}

export const getWozDocuments = async (
  context: ActionContext<WozContentState, RootState>,
  payload: {
    query: string
    page: number
  }
) => {
  const { query, page } = payload
  context.commit(types.SET_WOZ_CONTENT_DOCUMENTS_LOADING, true)
  if (!query) {
    context.commit(types.SET_LAST_SEARCH_PARAMETERS, { query: '', page: 1 })
    context.commit(types.SET_HAS_ANY_DOCUMENTS, false)
  }

  let response
  try {
    response = await getDocuments(query, page, PAGE_SIZE)
  } catch (ex) {
    console.error('Error on get documents', { ex })
    throw ex
  } finally {
    context.commit(types.SET_WOZ_CONTENT_DOCUMENTS_LOADING, false)
  }

  const { documents, paginationInfo } = response

  if (!query && (documents.length || paginationInfo.kbItemsCount)) {
    context.commit(types.SET_HAS_ANY_DOCUMENTS, true)
  }

  const documentsToStash = getDocumentsToStash(documents, page, PAGE_SIZE)

  context.commit(types.SET_WOZ_CONTENT_DOCUMENTS, documentsToStash)

  const tableDocuments = getTableDocuments(
    documentsToStash,
    response.paginationInfo
  )
  context.commit(types.SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE, tableDocuments)

  context.commit(types.SET_LAST_SEARCH_PARAMETERS, { query, page })

  return { documents: tableDocuments, pageInformation: response.paginationInfo }
}

export const getAgentWozDocuments = async (
  context: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    query: string
    page: number
  }
) => {
  const { query, agentId, page } = payload
  context.commit(types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING, true)
  if (!query) {
    context.commit(types.SET_LAST_SEARCH_PARAMETERS, {
      query: '',
      page: 1,
      agentId: undefined
    })
    context.commit(types.SET_AGENT_HAS_ANY_DOCUMENTS, false)
  }

  let response
  try {
    response = await getAgentDocuments(agentId, query, page, PAGE_SIZE)
  } catch (ex) {
    console.error('Error on get agent documents', { ex })
    throw ex
  } finally {
    context.commit(types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING, false)
  }

  const { documents, paginationInfo } = response

  if (!query && (documents.length || paginationInfo.kbItemsCount)) {
    context.commit(types.SET_AGENT_HAS_ANY_DOCUMENTS, true)
  }

  const documentsToStash = getDocumentsToStash(documents, page, PAGE_SIZE)
  context.commit(types.SET_AGENT_WOZ_CONTENT_DOCUMENTS, documentsToStash)

  const tableDocuments = getTableDocuments(
    documentsToStash,
    response.paginationInfo
  )
  context.commit(
    types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_FOR_TABLE,
    tableDocuments
  )

  context.commit(types.SET_LAST_SEARCH_PARAMETERS, { query: page, agentId })

  return { documents: tableDocuments, pageInformation: response.paginationInfo }
}

export const getWozDocument = (
  _: ActionContext<WozContentState, RootState>,
  id: string
) => {
  return getDocument(id).catch(ex =>
    console.error('Error on get document', { ex, id })
  )
}

export const enableWozDocument = (
  _: ActionContext<WozContentState, RootState>,
  id: string
) => {
  return (
    id === WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE
      ? toggleKBDocuments(true)
      : updateActiveDocument(id, true)
  ).catch(ex => console.error('Error on enable document', { ex, id }))
}

export const disableWozDocument = (
  _: ActionContext<WozContentState, RootState>,
  id: string
) => {
  return (
    id === WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE
      ? toggleKBDocuments(false)
      : updateActiveDocument(id, false)
  ).catch(ex => console.error('Error on disable document', { ex, id }))
}

export const bulkProcessWozDocument = (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    ids: string[]
    activate: boolean
  }
) => {
  return bulkActiveDocument(payload.ids, payload.activate).catch(ex =>
    console.error('Error on bulk process document', { ex, payload })
  )
}

export const executeDeleteDocument = async (
  _: ActionContext<WozContentState, RootState>,
  id: string
) => {
  await deleteDocument(id).catch(ex =>
    console.error('Error on delete document', { ex, id })
  )
}

export const executeBulkDelete = async (
  _: ActionContext<WozContentState, RootState>,
  ids: string[]
) => {
  await bulkDelete(ids).catch(ex =>
    console.error('Error on delete document', { ex, ids })
  )
}

const resolveChildrenDocumentsFilter = (parentId: string, query: string) => {
  let filter = `parentId=${parentId}`
  if (parentId === WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE)
    filter = 'source=KnowledgeBase'

  if (query) filter += '&' + query

  return filter
}

export const searchChildrenDocuments = async (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    parentId: string
    query: string
    page: number
  }
) => {
  const { parentId, query, page } = payload

  const filter = resolveChildrenDocumentsFilter(parentId, query)

  try {
    const response = await getDocuments(filter, page, PAGE_SIZE)
    const documents = response.documents
    return getTableDocumentsClient(documents, page, PAGE_SIZE)
  } catch (ex) {
    console.error('Error on search children documents', { ex, payload })
    throw ex
  }
}

export const searchChildrenAgentDocuments = async (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    parentId: string
    query: string
    page: number
  }
) => {
  const { agentId, parentId, query, page } = payload

  const filter = resolveChildrenDocumentsFilter(parentId, query)

  try {
    const response = await getAgentDocuments(agentId, filter, page, PAGE_SIZE)

    const documents = response.documents
    return getTableDocumentsClient(documents, page, PAGE_SIZE)
  } catch (ex) {
    console.error('Error on search children agent documents', { ex, payload })
    throw ex
  }
}

const getTableDocumentsClient = (
  documents: any[],
  page: number,
  pageSize: number
) => {
  return getDocumentsToStash(documents, page, pageSize).map(
    (doc: WozDocument) => {
      const activeStatus = doc.active ? 'active' : 'inactive'
      const originalState =
        doc.status === 'COMPLETED' ? activeStatus : 'processing'

      const document = getTableDocument({
        ...doc,
        originalState
      })

      if (document.source === WOZ_MEMORY_SOURCE.KnowledgeBase) {
        document.parentId = WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE
      }

      return document
    }
  )
}

const getDocumentsToStash = (
  documents: any[],
  page: number,
  pageSize: number
): WozDocument[] => {
  return documents.map((document: any) => {
    return {
      id: document.id,
      active: document.active,
      source: document.source,
      parentId: document.parentId,
      score: document.score,
      title: document.metadata?.title || document.externalKey,
      status: document.status,
      hasChild: document?.childrenCount > 0,
      childrenCount: document.childrenCount,
      currentPage: page,
      totalPages: 1 + Math.floor(document.childrenCount / pageSize),
      searching: false
    }
  })
}

export const linkDocumentsToAgent = async (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    documentIds: Array<string>
  }
) => {
  await linkContentToAgent(payload.agentId, payload.documentIds).catch(ex =>
    console.error('Error on link documents to agent', { ex, payload })
  )
}

export const bulkProcessLinkAgentDocument = (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    ids: string[]
    activate: boolean
    agentId: string
  }
) => {
  return bulkProcessAgentDocuments(
    payload.agentId,
    payload.ids,
    payload.activate
  ).catch(ex =>
    console.error('Error on bulk link document to agent', { ex, payload })
  )
}

export const bulkUnlinkAgentDocuments = (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    ids: string[]
    agentId: string
  }
) => {
  return unlinkAgentDocuments(payload.agentId, payload.ids).catch(ex =>
    console.error('Error on bulk unlink document to agent', { ex, payload })
  )
}

export const enableLinkAgentDocument = (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    id: string
  }
) => {
  const { agentId, id } = payload
  return (
    id === 'kb'
      ? toggleLinkKBDocumentsAgent(agentId, true)
      : updateActiveLinkDocumentAgent(agentId, id, true)
  ).catch(ex =>
    console.error('Error on enable document link to agent', { ex, payload })
  )
}

export const disableLinkAgentDocument = (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    id: string
  }
) => {
  const { agentId, id } = payload
  return (
    id === 'kb'
      ? toggleLinkKBDocumentsAgent(agentId, false)
      : updateActiveLinkDocumentAgent(agentId, id, false)
  ).catch(ex =>
    console.error('Error on disable document link to agent', { ex, payload })
  )
}
