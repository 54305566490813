import { AxiosInstance } from 'axios'

import {
  CommerceOnboardingAvailableSectors,
  CommerceOnboardingAvailableTools,
  CommerceOnboardingState
} from '@/store/interfaces/commerce-onboarding'

import { getClient } from './http'
import legacyHeaders from './legacy-headers'

export class CommerceOnboardingService {
  getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  async getCommerceOnboardingAvailableSectors(): Promise<
    CommerceOnboardingAvailableSectors[] | undefined
  > {
    try {
      const { data } = await this.getClient('commerce').then(client =>
        client.get('/companies/sectors', {
          ...legacyHeaders()
        })
      )

      return data
    } catch (ex) {
      throw new Error(
        'Error on get Commerce onboarding available sectores: ' + ex
      )
    }
  }

  async getCommerceOnboardingAvailableTools(): Promise<
    CommerceOnboardingAvailableTools[] | undefined
  > {
    try {
      const { data } = await this.getClient('commerce').then(client =>
        client.get('/companies/tools', {
          ...legacyHeaders()
        })
      )

      return data
    } catch (ex) {
      throw new Error('Error on get Commerce onboarding available tools: ' + ex)
    }
  }

  async saveCommerceOnboardingProps(
    data: Omit<
      CommerceOnboardingState,
      'initialDataLoader' | 'currentOnboardingStep' | 'selectsOptions'
    >
  ): Promise<void> {
    try {
      await this.getClient('commerce').then(client =>
        client.post('/companies', data, {
          ...legacyHeaders()
        })
      )
    } catch (ex) {
      throw new Error('Error on save Commerce onboarding props: ' + ex)
    }
  }
}
