/* https://bl.ocks.org/redgeoff/eadebc99521bc4ff7457f8587df6a6e0 */
const PasteImage = function (el) {
  this._el = el
  this._listenForPaste()
}

PasteImage.prototype._getURLObj = function () {
  return window.URL || window.webkitURL
}

PasteImage.prototype._pasteImage = function (image, blob) {
  this.emit('paste-image', { image, blob })
}

PasteImage.prototype._pasteImageSource = function (src, blob) {
  const self = this,
    image = new Image()

  image.onload = function () {
    self._pasteImage(image, blob)
  }

  image.src = src
}

PasteImage.prototype._onPaste = function (e) {
  // We need to check if event.clipboardData is supported (Chrome & IE)
  if (e.clipboardData && e.clipboardData.items) {
    // Get the items from the clipboard
    const items = e.clipboardData.items

    // Loop through all items, looking for any kind of image
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        // We need to represent the image as a file
        const blob = items[i].getAsFile()

        // Use a URL or webkitURL (whichever is available to the browser) to create a
        // temporary URL to the object
        const URLObj = this._getURLObj()
        const source = URLObj.createObjectURL(blob)

        // The URL can then be used as the source of an image
        this._pasteImageSource(source, blob)

        // Prevent the image (or URL) from being pasted into the contenteditable element
        e.preventDefault()
      }
    }
  }
}

PasteImage.prototype._listenForPaste = function () {
  const self = this

  self._origOnPaste = self._el.onpaste
  self._pasteImageEventListener = function (e) {
    self._onPaste(e)

    // Preserve an existing onpaste event handler
    if (self._origOnPaste) {
      self._origOnPaste.apply(this)
    }
  }

  self._el.addEventListener('paste', self._pasteImageEventListener)
}

// TODO: use EventEmitter instead
PasteImage.prototype.on = function (_, callback) {
  this._callback = callback
}

PasteImage.prototype.destroy = function () {
  const self = this
  self._el.removeEventListener('paste', self._pasteImageEventListener)
}
// TODO: use EventEmitter instead
PasteImage.prototype.emit = function (_, arg) {
  this._callback(arg)
}

// -----

export default PasteImage
