export enum CommerceOnboardingSteps {
  companySector = 'CompanySector',
  aboutCompany = 'AboutCompany',
  userActivities = 'UserActivities'
}

export const CommerceSectorCode = 'ecommerceRetail'

export enum CompanyCommerceTypes {
  services = 'services',
  products = 'products'
}

export enum AboutCompanyOptions {
  starting = 'starting',
  consolidated = 'consolidated'
}

export enum AvailableToolsConsolidated {
  whatsAppApi = 'whatsAppApi',

  tray = 'tray',
  magento = 'magento',
  others = 'others'
}

export enum AvailableToolsStarting {
  whatsAppWeb = 'whatsAppWeb',
  instagram = 'instagram',
  messenger = 'messenger',
  chat = 'chat'
}

export type AvailableTools = AvailableToolsConsolidated | AvailableToolsStarting
