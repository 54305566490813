const chatAgentsBaseURL =
  window.octadesk &&
  window.octadesk.apis &&
  (window.octadesk.apis.chatAgentsUrl || window.octadesk.apis.chatUrl)
const chatIntegratorBaseURL =
  window.octadesk &&
  window.octadesk.apis &&
  (window.octadesk.apis.chatIntegratorUrl || window.octadesk.apis.chatUrl)

export default {
  chat: {
    agents: `${chatAgentsBaseURL}/agents`,
    integrator: `${chatIntegratorBaseURL}/integrator`
  }
}
