import { environment } from '@octadesk-tech/services'

export const getNewWidgetUrl = (subDomain: string): string => {
  const buckets: Record<string, string> = {
    [environment.DEFAULT_ENVIRONMENTS.qa]: 'cdn-qaoctadesk-com-cdn-bucket',
    [environment.DEFAULT_ENVIRONMENTS.prd]: 'octa-static-files'
  }

  const env = environment.getEnvironment() as string
  return `https://storage.googleapis.com/${buckets[env]}/new-widget/embed/${subDomain}/embed.js`
}
