import { OnboardingChannels } from '@/common/helpers/enums'

export const onboardingChannels: Array<Record<string, any>> = [
  {
    name: OnboardingChannels.Instagram,
    label: 'onboarding.intagram.label',
    imageUrl: '/images/Onboarding/instagram.svg',
    description: 'onboarding.instagram.description',
    route: '/chat-settings/other-channels/messenger',
    action: 'onboarding.instagram.action',
    canTest: true
  },
  {
    name: OnboardingChannels.Messenger,
    label: 'onboarding.messenger.label',
    imageUrl: '/images/Onboarding/messenger.svg',
    description: 'onboarding.messenger.description',
    route: '/chat-settings/other-channels/messenger',
    action: 'onboarding.messenger.action',
    canTest: true
  },
  {
    name: OnboardingChannels.Chat,
    label: 'onboarding.chat.label',
    imageUrl: '/images/Onboarding/chat.svg',
    description: 'onboarding.chat.description',
    route: '/chat-settings/other-channels/chat',
    action: 'onboarding.chat.action',
    canTest: true
  },
  {
    name: OnboardingChannels.WhatsApp,
    label: 'onboarding.whatsapp.label',
    description: 'onboarding.whatsapp.description',
    imageUrl: '/images/Onboarding/whatsapp.svg',
    route: '/chat-settings/whatsapp-oficial/numbers',
    action: 'onboarding.whatsapp.action',
    canTest: false
  },
  {
    name: OnboardingChannels.Email,
    label: 'onboarding.email.label',
    subtitle: 'onboarding.email.subtitle',
    imageUrl: '/images/Onboarding/email.svg',
    description: 'onboarding.email.description',
    route: '/ticket',
    action: 'onboarding.email.action',
    canTest: false
  }
]
