import {
  features,
  notificationType
} from '@/modules/Chat/components/sidebar/enums'

export default [
  {
    enabled: true,
    forbiddenFeatures: [features.whatsAppBusinessAPI],
    mandatoryFeatures: [features.whatsAppAPIAds],
    notificationType: notificationType.WHATSAPP_API_ADS_NOTIFICATION
  }
]
