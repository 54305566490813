import { NavigationGuardNext } from 'vue-router'

import { RoleTypes } from '@/common/helpers/enums'

import store from '@/store'

import { EventHandlersRoutes } from './event-handler/routes.enum'

const handleRedirect = (
  next: NavigationGuardNext,
  getterName: string,
  routePath: string
) => {
  if (!store.getters[getterName]) {
    next(routePath)
  }

  next()
}

export default {
  path: 'general',
  name: 'chat-settings-general',
  redirect: '/chat-settings/general/chat-preferences',
  component: () => import('./General.vue'),
  children: [
    {
      path: 'chat-preferences',
      name: 'chat-settings-chat-preferences',
      component: () =>
        import('./chat-preferences/components/SettingsAccountConfiguration.vue')
    },
    {
      path: 'notifications',
      name: 'chat-settings-notifications',
      component: () => import('./notifications/Notifications.vue')
    },
    {
      path: 'tags',
      name: 'chat-settings-tags',
      redirect: '/chat-settings/general/tags/list/active',
      component: () =>
        import('./tags-manager/components/SettingsTagsManager.vue'),
      children: [
        {
          path: 'list/:status',
          name: 'chat-settings-tags-list',
          component: () =>
            import('./tags-manager/components/tags-list/TagsList.vue')
        }
      ]
    },
    {
      path: 'survey',
      name: 'chat-settings-survey',
      component: () => import('./survey/components/Survey.vue'),
      redirect: '/chat-settings/general/survey/list',
      children: [
        {
          path: 'list',
          name: 'chat-settings-survey-list',
          component: () => import('./survey/components/SurveyChannelsList.vue')
        },
        {
          path: 'whatsapp',
          name: 'chat-settings-survey-whatsapp',
          component: () =>
            import('./survey/components/whatsapp/SurveyChannelWhatsApp.vue')
        }
      ]
    },
    {
      path: 'chat-preferences/response-time',
      name: 'chat-preferences-response-time',
      component: () =>
        import(
          './chat-preferences/components/additional/conversation-response-time/ConversationResponseTimeConfiguration.vue'
        ),
      beforeEnter: (_: any, __: any, next: any) => {
        handleRedirect(
          next,
          'isResponseServiceTimeoutEnabled',
          '/chat-settings/chat-preferences'
        )
      }
    },
    {
      path: 'event-handlers',
      name: 'chat-settings-event-handlers',
      component: () => import('./event-handler/components/EventHandler.vue'),
      redirect: EventHandlersRoutes.LIST,
      meta: {
        permissionLevel: RoleTypes.Agent
      },
      children: [
        {
          path: '',
          name: EventHandlersRoutes.LIST,
          meta: {
            permissionLevel: RoleTypes.Agent
          },
          component: () =>
            import('./event-handler/components/list/EventHandlerList.vue')
        },
        {
          path: 'create',
          name: EventHandlersRoutes.CREATE,
          meta: {
            permissionLevel: RoleTypes.Agent
          },
          component: () =>
            import('./event-handler/components/edit/EventHandlerContainer.vue')
        },
        {
          path: ':id',
          name: EventHandlersRoutes.EDIT,
          props: true,
          meta: {
            permissionLevel: RoleTypes.Agent
          },
          component: () =>
            import('./event-handler/components/edit/EventHandlerContainer.vue')
        },
        {
          path: ':id/bot',
          name: EventHandlersRoutes.EDIT_BOT,
          props: true,
          meta: {
            permissionLevel: RoleTypes.Agent
          },
          component: () =>
            import(
              './event-handler/components/edit/bot/EventHandlerBotEditor.vue'
            )
        }
      ]
    },
    {
      path: 'api-activation',
      name: 'chat-settings-whatsapp-oficial-api-activation',
      component: () => import('./api-activation/components/ApiActivation.vue'),
      beforeEnter: (_: any, __: any, next: any) => {
        handleRedirect(next, 'isApiKeyManagementEnabled', '/chat-settings/chat')
      }
    },
    {
      path: 'security',
      name: 'chat-settings-security',
      component: () => import('./security/components/Security.vue')
    }
  ]
}
