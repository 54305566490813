import { getClient as getHttpClient } from './http'
import legacyHeaders from './legacy-headers'

export class TicketService {
  private API_NAME = 'ticket'

  async getClient() {
    return getHttpClient(this.API_NAME)
  }

  async getTicketStatus(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/status?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }

  async getTicketPriority(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/priorities?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }

  async getTicketType(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/types?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }

  async getTicketChannel(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/channels?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }

  async getTicketTags(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/tags?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }
}
