import OctaAnalyticsClient from '@octadesk-tech/analytics-client'

import { EVENTS, PRODUCT_TARGETS } from '@/common/helpers/enums'

interface TrackingProperties {
  source?: string
}

export const segmentTracking = async ({ source }: TrackingProperties) => {
  await OctaAnalyticsClient.SegmentIntegration.track(
    EVENTS.NEW_PRODUCT_ROLLBACK,
    {
      target: PRODUCT_TARGETS.NEW_PRODUCT,
      source
    }
  )
}
