import { TicketBaseAttribute } from '@/common/helpers/interfaces/ticket-base'

import { TicketService } from '@/common/services'

import { DataSourceResolver } from './interfaces'

let service: TicketService
const getService = () => {
  if (service) {
    return service
  }
  service = new TicketService()
  return service
}

export const ticketStatus: DataSourceResolver = searchValue =>
  getService()
    .getTicketStatus(searchValue)
    .then((status: TicketBaseAttribute[]) =>
      status.map(status => ({
        id: status.id as string,
        name: status.name as string
      }))
    )

export const ticketPriorities: DataSourceResolver = searchValue =>
  getService()
    .getTicketPriority(searchValue)
    .then((status: TicketBaseAttribute[]) =>
      status.map(status => ({
        id: status.id as string,
        name: status.name as string
      }))
    )

export const ticketTypes: DataSourceResolver = searchValue =>
  getService()
    .getTicketType(searchValue)
    .then((status: TicketBaseAttribute[]) =>
      status.map(status => ({
        id: status.id as string,
        name: status.name as string
      }))
    )

export const ticketChannels: DataSourceResolver = searchValue =>
  getService()
    .getTicketChannel(searchValue)
    .then((status: TicketBaseAttribute[]) =>
      status.map(status => ({
        id: status.id as string,
        name: status.name as string
      }))
    )

export const ticketTags: DataSourceResolver = searchValue =>
  getService()
    .getTicketTags(searchValue)
    .then((status: TicketBaseAttribute[]) =>
      status.map(status => ({
        id: status.id as string,
        name: status.name as string
      }))
    )
