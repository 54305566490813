import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'
import { headers } from '@octadesk-tech/services'

import { ChatGroup } from '@/common/helpers/interfaces'

import { Group } from './store/interfaces'

export class GroupsService {
  _client: any

  async getClient(): Promise<AxiosInstance> {
    return (await services.chat.getClient()) as AxiosInstance
  }

  async getAgentClient() {
    if (this._client) {
      return this._client
    }

    this._client = await services.chatAgents.getClient()

    return this._client
  }

  async getGroups() {
    return this.getClient()
      .then(client =>
        client.get<ChatGroup[]>('groups', headers.getAuthorizedHeaders())
      )
      .then(res => res.data)
  }

  async enableGroup(group: Group) {
    return this.getClient().then(client =>
      client.put(
        `groups/${group.id}/enable`,
        undefined,
        headers.getAuthorizedHeaders()
      )
    )
  }

  async disableGroup(group: Group) {
    return this.getClient().then(client =>
      client.put(
        `groups/${group.id}/disable`,
        undefined,
        headers.getAuthorizedHeaders()
      )
    )
  }

  async updateGroup(group: Group) {
    return this.getClient().then(client =>
      client.put(`groups/${group.id}`, group, headers.getAuthorizedHeaders())
    )
  }

  async createGroup(group: Group) {
    return this.getClient().then(client =>
      client.post('groups', group, headers.getAuthorizedHeaders())
    )
  }

  async removeGroup(group: Group) {
    return this.getClient().then(client =>
      client.delete(`groups/${group.id}`, headers.getAuthorizedHeaders())
    )
  }

  async removeGroups(groups: Array<Group>) {
    return this.getClient().then(client =>
      client.post(`groups/bulk/delete`, groups, headers.getAuthorizedHeaders())
    )
  }

  async getAgents() {
    return this.getAgentClient()
      .then(client => client.get('', headers.getAuthorizedHeaders()))
      .then(res => res.data)
  }

  async activeAgent(idAgent: string) {
    return this.getAgentClient()
      .then(client =>
        client.put(`/${idAgent}/active`, {}, headers.getAuthorizedHeaders())
      )
      .then(res => res.data)
  }

  async getWhatsappNumbers() {
    return this.getClient()
      .then(client =>
        client.get('channel/numbers', headers.getAuthorizedHeaders())
      )
      .then(res => res.data)
  }

  async pullAllowedNumbers(numberToPull: string) {
    await this.getClient().then(client =>
      client.put(
        `groups/numbers/${numberToPull}/pull`,
        undefined,
        headers.getAuthorizedHeaders()
      )
    )
  }
}
