import { InboxTypes } from '@/common/helpers/enums'

import { ChatState } from '@/store/interfaces'

const state: ChatState = {
  inboxCounters: {
    [InboxTypes.ALL]: undefined,
    [InboxTypes.BY_AGENT]: undefined,
    [InboxTypes.UNREAD]: undefined,
    [InboxTypes.MENTIONED]: undefined,
    [InboxTypes.PARTICIPATION]: undefined,
    [InboxTypes.WITHOUT_RESPONSIBLE]: undefined,
    [InboxTypes.CHATBOT]: undefined
  },
  inboxCountersGroups: undefined,
  groupInboxNameSelected: undefined,
  inboxGroupsEdit: undefined,
  chatSettings: undefined,
  openChatConversationRoomKey: undefined
}
export default state
