import qs from 'qs'
import { v4 } from 'uuid'

import {
  FilterTypes,
  CommerceEnum
} from '@/modules/Chat/components/conversation/enums'
import * as services from '@/modules/Chat/components/conversation/services/commerce'
import * as types from '@/modules/Chat/components/conversation/store/mutations-types/commerce'
import { CHECKOUT_INITIAL_STATE } from '@/modules/Chat/components/conversation/store/state/commerce'

import i18n from '@/common/i18n'

const getDefaultAttributes = attributes =>
  attributes.map(attribute => ({
    ...attribute,
    values: attribute.values.map(variation => ({
      ...variation,
      selected: false,
      available: true
    }))
  }))

function getActiveCatalog(catalogs) {
  return catalogs.find(catalog => catalog.active)
}

export const getCommerceCatalogs = async context => {
  const { commerceHasCatalog } = context.getters

  if (!commerceHasCatalog) {
    context.commit(types.SET_COMMERCE_CATALOGS_LOADER, true)

    const catalogs = await services.getCommerceCatalogsService()

    if (catalogs?.length) {
      context.commit(types.SET_COMMERCE_CATALOGS, getActiveCatalog(catalogs))

      await context.dispatch('getCommerceProducts', { page: 1 })

      await context.dispatch('getCommerceFilters')

      await context.dispatch('getCommerceOrderBy')
    }

    context.commit(types.SET_COMMERCE_CATALOGS_LOADER, false)
  }
}

export const getCommerceProducts = async (context, { page }) => {
  const { commerceCatalog } = context.getters

  context.commit(types.SET_COMMERCE_PRODUCTS_PAGINATE_LOADER, true)

  const items = await services.getCommerceProductsService({
    page,
    catalogs: commerceCatalog?.id
  })

  const products = items.map(product => ({
    ...product,
    retailerId: product.sku
  }))

  const allProductsLoaded = products.length < CommerceEnum.ProductsPerPage

  context.commit(types.SET_COMMERCE_PRODUCTS, products)

  context.commit(types.SET_COMMERCE_PRODUCTS_PAGE, page)

  context.commit(types.SET_COMMERCE_ALL_PRODUCTS_LOADED, allProductsLoaded)

  context.commit(types.SET_COMMERCE_PRODUCTS_PAGINATE_LOADER, false)
}

export const getCommerceProductDetails = async (context, { productId }) => {
  context.commit(types.SET_COMMERCE_PRODUCT_DETAILS_LOADER, true)

  const { attributes, ...product } =
    await services.getCommerceProductDetailsService({
      productId
    })

  context.commit(types.SET_COMMERCE_PRODUCT_DETAILS, product)

  if (attributes) {
    context.commit(
      types.SET_COMMERCE_PRODUCT_DETAILS_ATTRIBUTES,
      getDefaultAttributes(attributes)
    )
  }

  context.commit(types.SET_COMMERCE_PRODUCT_DETAILS_VARIATION, {})

  context.commit(types.SET_COMMERCE_PRODUCT_DETAILS_CLICKED_TO_SEND, false)

  context.commit(types.SET_COMMERCE_PRODUCT_DETAILS_LOADER, false)
}

export const setCommerceProductDetailsAttributeVariation = async (
  context,
  { attributeId, variationId }
) => {
  const { commerceProductDetails } = context.getters

  const { attributes } = commerceProductDetails

  const parsedAttributes = attributes.map(attribute => {
    let firstSelected = attribute.firstSelected || false

    if (
      !attributes.find(at => at.firstSelected) &&
      attribute.id === attributeId
    ) {
      firstSelected = true
    }

    return {
      ...attribute,
      firstSelected,
      values: attribute.values.map(variation => {
        let selected = variation.selected

        if (variation.id === variationId) {
          selected = true
        } else if (
          attribute.id === attributeId &&
          variation.id !== variationId
        ) {
          selected = false
        }

        return {
          ...variation,
          selected
        }
      })
    }
  })

  await context.dispatch(
    'getCommerceProductAttributesCombinations',
    parsedAttributes
  )
}

export const getCommerceProductAttributesCombinations = async (
  context,
  attributes
) => {
  const { commerceProductDetails } = context.getters

  const { product } = commerceProductDetails

  const attributeValueIds = attributes
    .map(
      attribute => attribute.values.find(variation => variation.selected)?.id
    )
    .filter(item => item)

  const productsVariations =
    await services.getCommerceProductAttributesCombinationsService({
      productId: product.id,
      attributes: JSON.stringify(attributeValueIds)
    })

  const parsedAttributes = attributes.map(attribute => ({
    ...attribute,
    values: attribute.values.map(variation => ({
      ...variation,
      available: Boolean(productsVariations?.length)
    }))
  }))

  context.commit(
    types.SET_COMMERCE_PRODUCT_DETAILS_ATTRIBUTES,
    parsedAttributes
  )

  const { commerceProductDetailsAllAttributeSelected } = context.getters

  if (
    commerceProductDetailsAllAttributeSelected &&
    productsVariations.length === 1
  ) {
    context.commit(
      types.SET_COMMERCE_PRODUCT_DETAILS_VARIATION,
      productsVariations[0]
    )
  }
}

export const clearCommerceProductDetailsAttributeVariation = context => {
  const { commerceProductDetails } = context.getters

  const { attributes } = commerceProductDetails

  context.commit(
    types.SET_COMMERCE_PRODUCT_DETAILS_ATTRIBUTES,
    getDefaultAttributes(attributes)
  )

  context.commit(types.SET_COMMERCE_PRODUCT_DETAILS_CLICKED_TO_SEND, false)

  context.commit(types.SET_COMMERCE_PRODUCT_DETAILS_VARIATION, {})
}

export const setCommerceProductDetailsClickedToSend = context => {
  context.commit(types.SET_COMMERCE_PRODUCT_DETAILS_CLICKED_TO_SEND, true)
}

export const getCommerceFilters = async context => {
  const filters = await services.getCommerceFiltersService()

  context.commit(
    types.SET_COMMERCE_FILTER_ITEMS,
    filters.sort((a, b) => (a.type > b.type ? -1 : 1))
  )
}

export const getCommerceProductsByFilters = async context => {
  context.commit(types.SET_COMMERCE_FILTERS_LOADER, true)

  const parsedFilters = {}

  const attributesKeyName = 'attributes'

  const {
    commerceSelectedCheckboxFilters,
    commerceFilters,
    commerceFilterQueryString,
    commerceOrderBy
  } = context.getters

  commerceSelectedCheckboxFilters.forEach(item => {
    const items = item.values.map(value => value.id)

    if (
      !Object.prototype.hasOwnProperty.call(parsedFilters, attributesKeyName)
    ) {
      parsedFilters[attributesKeyName] = {}
    }

    if (item?.source === attributesKeyName) {
      parsedFilters[attributesKeyName][item.id] = items

      return
    }

    parsedFilters[item.code] = items
  })

  const switchFilters = commerceFilters.filter(
    filter => filter.type === FilterTypes.Switch
  )

  if (switchFilters.length) {
    switchFilters.map(item => {
      parsedFilters[item.code] = item.selected
    })
  }

  if (commerceFilterQueryString) {
    parsedFilters['q'] = commerceFilterQueryString
  }

  if (commerceOrderBy) {
    const selectedOrderBy = commerceOrderBy.find(item => item.selected)

    if (selectedOrderBy) {
      const { type, value } = selectedOrderBy

      parsedFilters['sortBy'] = type

      parsedFilters['orderBy'] = value
    }
  }

  const filtersStringify = qs.stringify(parsedFilters)

  let products = []

  if (filtersStringify) {
    products = await services.getCommerceProductsByFiltersService(
      filtersStringify
    )
  }

  context.commit(types.SET_COMMERCE_FILTERED_ITEMS, products)

  context.commit(types.SET_COMMERCE_FILTERS_LOADER, false)
}

export const setCommerceFilterCheckbox = async (context, payload) => {
  const { commerceFilters } = context.getters

  const { filterId, optionId } = payload

  const selectedFilters = commerceFilters.map(filter => {
    if (filter.id === filterId && filter.type === FilterTypes.Checkbox) {
      return {
        ...filter,
        values: filter.values.map(item => {
          if (item.id === optionId) {
            return {
              ...item,
              selected: !item.selected
            }
          }

          return item
        })
      }
    }

    return filter
  })

  context.commit(types.SET_COMMERCE_FILTER_ITEMS, selectedFilters)

  await context.dispatch('getCommerceProductsByFilters')
}

export const setCommerceFilterSwitch = async (context, payload) => {
  const { commerceFilters } = context.getters

  const { filterId, value } = payload

  const selectedFilters = commerceFilters.map(filter => {
    if (filter.id === filterId && filter.type === FilterTypes.Switch) {
      return {
        ...filter,
        selected: value
      }
    }

    return filter
  })

  context.commit(types.SET_COMMERCE_FILTER_ITEMS, selectedFilters)

  await context.dispatch('getCommerceProductsByFilters')
}

export const clearCommerceFilterItem = async (context, filterCode) => {
  const { commerceFilters } = context.getters

  const selectedFilters = commerceFilters.map(filter => {
    if (filter.code === filterCode) {
      return {
        ...filter,
        values: filter.values.map(item => ({
          ...item,
          selected: false
        }))
      }
    }

    return filter
  })

  context.commit(types.SET_COMMERCE_FILTER_ITEMS, selectedFilters)

  const { commerceSelectedCheckboxFilters } = context.getters

  if (!commerceSelectedCheckboxFilters.length) {
    context.commit(types.SET_COMMERCE_FILTERED_ITEMS, [])
  } else {
    await context.dispatch('getCommerceProductsByFilters')
  }
}

export const clearCommerceFilter = context => {
  const { commerceFilters } = context.getters

  const selectedFilters = commerceFilters.map(filter => {
    if (
      filter.type === FilterTypes.Checkbox ||
      !Object.prototype.hasOwnProperty.call(filter, 'type')
    ) {
      return {
        ...filter,
        values: filter.values.map(item => ({
          ...item,
          selected: false
        }))
      }
    }

    return filter
  })

  context.commit(types.SET_COMMERCE_FILTER_ITEMS, selectedFilters)

  context.commit(types.SET_COMMERCE_FILTERED_ITEMS, [])

  context.commit(types.SET_COMMERCE_FILTER_QUERY_STRING, '')

  context.dispatch('clearCommerceOrderBy')
}

export const setCommerceFilterQueryString = async (context, term) => {
  context.commit(types.SET_COMMERCE_FILTER_QUERY_STRING, term)

  await context.dispatch('getCommerceProductsByFilters')
}

export const setCommerceSelectedProducts = (context, product) => {
  const { commerceSelectedProducts, chatKey } = context.getters

  const selectedProducts = commerceSelectedProducts

  let chatKeyIndex = selectedProducts.findIndex(
    item => item.chatKey === chatKey
  )

  if (chatKeyIndex <= -1) {
    selectedProducts.push({
      chatKey,
      items: []
    })

    chatKeyIndex = selectedProducts.findIndex(item => item.chatKey === chatKey)
  }

  const productIndex = selectedProducts[chatKeyIndex].items.findIndex(
    item => item.id && item.id === product.id
  )

  if (productIndex <= -1) {
    const parsedProduct = {
      ...product,
      retailerId: product.sku,
      productId: product.id
    }

    Array.from(['attributeValues', 'attributeValuesHash', 'variants']).forEach(
      key => delete parsedProduct[key]
    )

    selectedProducts[chatKeyIndex].items.push({
      ...parsedProduct
    })
  } else {
    selectedProducts[chatKeyIndex].items.splice(productIndex, 1)
  }

  context.commit(types.SET_COMMERCE_SELECTED_PRODUCTS, selectedProducts)
}

export const clearCommerceSelectedProducts = context => {
  context.commit(types.SET_COMMERCE_SELECTED_PRODUCTS, [])
}

export const getCommerceOrderBy = context => {
  const orderBy = [
    {
      id: v4(),
      type: 'offerPrice',
      title: i18n?.t(
        'chat.conversation.commerce.catalogsList.order.lowestPrice'
      ),
      value: 'asc'
    },
    {
      id: v4(),
      type: 'offerPrice',
      title: i18n?.t(
        'chat.conversation.commerce.catalogsList.order.biggestPrice'
      ),
      value: 'desc'
    }
  ]

  context.commit(types.SET_COMMERCE_ORDER_BY, orderBy)
}

export const setCommerceOrderBy = async (context, value) => {
  const { commerceOrderBy } = context.getters

  const orderBy = commerceOrderBy.map(item => ({
    ...item,
    selected: item.value === value
  }))

  context.commit(types.SET_COMMERCE_ORDER_BY, orderBy)

  await context.dispatch('getCommerceProductsByFilters')
}

export const clearCommerceOrderBy = context => {
  const { commerceOrderBy } = context.getters

  const orderBy = commerceOrderBy.map(item => ({
    ...item,
    selected: false
  }))

  context.commit(types.SET_COMMERCE_ORDER_BY, orderBy)
}

export const clearSelectedProducts = context => {
  const { chatKey, commerceSelectedProducts } = context.getters

  const selectedProducts = commerceSelectedProducts

  const chatKeyIndex = selectedProducts.findIndex(
    item => item.chatKey === chatKey
  )

  if (chatKeyIndex >= 0) {
    selectedProducts.splice(chatKeyIndex, 1)

    context.commit(types.SET_COMMERCE_SELECTED_PRODUCTS, selectedProducts)
  }
}

export const showCommerceProductsSended = async (context, productSkuIds) => {
  const { commerceProducts } = context.getters

  const products = commerceProducts.filter(product =>
    productSkuIds.includes(product.sku)
  )

  context.commit(types.SET_COMMERCE_PRODUCTS_SENDED, products)
}

export const getCommerceCart = async (context, { roomKey, messageId }) => {
  context.commit(types.SET_COMMERCE_CART_LOADER, true)

  const cart = await services.getCommerceCart({
    roomKey,
    messageId
  })

  context.commit(types.SET_COMMERCE_CART, cart)

  context.commit(types.SET_COMMERCE_CART_LOADER, false)
}

export const setCommerceMethodType = (context, type) => {
  context.commit(types.SET_COMMERCE_METHOD_TYPE, type)
}

export const addProductToCreateCart = (context, product) => {
  const { commerceCreateCartProducts } = context.getters

  let newProducts = commerceCreateCartProducts

  const hasProduct = commerceCreateCartProducts.find(
    item => item.id === product.id && !item.observations
  )

  if (hasProduct) {
    newProducts = newProducts.map(item => {
      if (item.id === product.id) {
        item.quantity = item.quantity + 1
      }

      return item
    })
  } else {
    newProducts.push({
      ...product,
      quantity: 1,
      observations: '',
      createCartProductId: v4()
    })
  }

  context.commit(types.UPDATE_CREATE_CART_PRODUCTS, newProducts)
}

export const removeProductToCreateCart = (context, createCartProductId) => {
  const { commerceCreateCartProducts } = context.getters

  const newProducts = commerceCreateCartProducts.filter(
    product => product.createCartProductId !== createCartProductId
  )

  context.commit(types.UPDATE_CREATE_CART_PRODUCTS, newProducts)
}

export const updateProductCreateCart = (context, product) => {
  const { commerceCreateCartProducts } = context.getters

  const newProducts = commerceCreateCartProducts.map(item => {
    if (item.createCartProductId === product.createCartProductId) {
      item = product
    }

    return item
  })

  context.commit(types.UPDATE_CREATE_CART_PRODUCTS, newProducts)
}

export const clearProductsCreateCart = context => {
  context.commit(types.UPDATE_CREATE_CART_PRODUCTS, [])
}

export const updateCheckoutField = (context, checkout) => {
  context.commit(types.UPDATE_CHECKOUT_FIELDS, checkout)
}

export const setCommerceCreateCartOrder = (context, order) => {
  context.commit(types.SET_COMMERCE_CREATE_CART_ORDER, order)
}

export const clearCheckoutFields = context => {
  context.commit(types.UPDATE_CHECKOUT_FIELDS, CHECKOUT_INITIAL_STATE)
}
