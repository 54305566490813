import { mapGetters, mapActions } from 'vuex'

import { getPrefixedDomain, getNewWidgetUrl } from '@/common/helpers/utils'

export default {
  data() {
    return {
      isNewWidget: false,
      scriptReady: false,
      widgetReady: false,
      widgetLoading: false,
      mixinSubDomain: ''
    }
  },
  computed: {
    ...mapGetters(['userLogged'])
  },
  methods: {
    ...mapActions({ setLoadedWidget: 'onboarding/setLoadedWidget' }),
    $_handleInitializeWidgetScript(subDomain = 'help') {
      const urlSearchParams = new URLSearchParams(window.location.search)
      this.isNewWidget =
        Object.fromEntries(urlSearchParams.entries())?.isNewWidget || false

      if (this.isNewWidget) {
        this.initializeNewWidgetScript(subDomain)
      } else {
        this.initializeWidgetScript(subDomain)
      }
    },
    initializeNewWidgetScript(subDomain) {
      const body = document.getElementsByTagName('body')[0]

      const script = document.createElement('script')

      script.async = true

      script.onload = this.$_handlescriptReady

      script.src = getNewWidgetUrl(subDomain)

      body.appendChild(script)
    },
    initializeWidgetScript(subDomain) {
      this.mixinSubDomain = subDomain

      const ready = window?.octadesk?.chat?.isReady?.(subDomain) ?? false

      if (!ready && !this.scriptReady) {
        this.widgetLoading = true

        this.scriptReady = true

        window.octadesk = window.octadesk || {}

        const body = document.getElementsByTagName('body')[0]

        const script = document.createElement('script')

        const prefixedDomain = getPrefixedDomain()

        window.octadesk.chatOptions = {
          subDomain: subDomain,
          showButton: 'false',
          openOnMessage: 'true',
          hide: 'true',
          id: subDomain
        }

        if (subDomain == 'help') {
          window.octadesk.chatOptions.embedURL =
            'https://cdn.octadesk.com/old-widget-static-beta/index.html'
        }
        script.async = true

        script.onload = this.$_handlescriptReady

        script.src = `https://cdn.${prefixedDomain}.com/embed.js`

        body.appendChild(script)
      } else {
        this.$_handleOpenWidget()
      }
    },
    $_handlescriptReady() {
      if (!this.isNewWidget) {
        this.$_handleAutoLogin()
      }
    },
    $_handleAutoLogin() {
      let tries = 0

      const maxTries = 300
      const timeInterval = 100

      const loginInterval = setInterval(() => {
        if (tries >= maxTries) {
          clearInterval(loginInterval)

          console.error('Error on autoLogin: missing chat widget script')

          return
        }
        if (window.octadesk.chat) {
          const isReady =
            window?.octadesk?.chat?.isReady?.(this.mixinSubDomain) ?? false

          if (this.userLogged && isReady) {
            this.widgetReady = true

            this.widgetLoading = false

            const autoLoginPayload = {
              user: {
                name: this.userLogged.name,
                email: this.userLogged.email
              },

              customFields: []
            }

            window.octadesk?.chat?.login(autoLoginPayload, this.mixinSubDomain)

            setTimeout(() => {
              this.$_handleOpenWidget()
            }, 1000)

            clearInterval(loginInterval)
          }
        }
        tries++
      }, timeInterval)
    },
    $_handleOpenWidget() {
      window.octadesk?.chat?.show(this.mixinSubDomain)

      window.octadesk?.chat?.showApp(this.mixinSubDomain)

      this.$emit('toggle')
      this.setLoadedWidget(true)
    }
  }
}
