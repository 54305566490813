import { HUBSPOT_EVENT, HUBSPOT_ID } from '@/common/helpers/enums'
import { HubspotFormField } from '@/common/helpers/interfaces'

export const hubspotEvents: Record<string, Array<HubspotFormField>> = {
  [HUBSPOT_EVENT.WHATSAPP_WEB]: [
    {
      name: HUBSPOT_ID.CHANNEL_CONFIGURED,
      value: HUBSPOT_EVENT.WHATSAPP_WEB
    }
  ],
  [HUBSPOT_EVENT.PLAN_SELECTED]: [
    {
      name: HUBSPOT_ID.PLAN_SELECTED,
      value: HUBSPOT_EVENT.PLAN_SELECTED
    }
  ],
  [HUBSPOT_EVENT.USER_ADDED]: [
    {
      name: HUBSPOT_ID.USER_ADDED,
      value: HUBSPOT_EVENT.USER_ADDED
    }
  ],
  [HUBSPOT_EVENT.DASHBOARD_VIEWED]: [
    {
      name: HUBSPOT_ID.DASHBOARD_VIEWED,
      value: HUBSPOT_EVENT.DASHBOARD_VIEWED
    }
  ]
}
