export enum FormSteps {
  BasicInformations = 'BasicInformationsContainer',
  TemplateMessage = 'TemplateMessageContainer',
  SpreadSheet = 'SpreadSheetContainer'
}

export enum FormId {
  BasicInformations = 'form-bulk-basic-container',
  TemplateMessage = 'template-message-container',
  SpreadSheet = 'form-bulk-spread-container'
}

export enum TemplateMessageComponentType {
  Header = 'HEADER',
  Body = 'BODY',
  Footer = 'FOOTER',
  Buttons = 'BUTTONS'
}

export enum Channels {
  WhatsApp = 'whatsapp'
}

export enum VariablesTypes {
  Text = 'text',
  Location = 'location',
  Image = 'image'
}

export enum LocationFields {
  Name = 'name',
  ZipCode = 'zipCode',
  Address = 'address',
  Number = 'number',
  Neighborhood = 'neighborhood',
  State = 'state',
  City = 'city',
  Complement = 'complement',
  Latitude = 'latitude',
  Longitude = 'longitude'
}

export enum DomainTypes {
  Perons = 'person'
}

export enum SendTypes {
  Immediate = 'immediate',
  Scheduled = 'scheduled'
}

export enum SuccessModalTypes {
  StartedSuccessfully = 'startedSuccessfully',
  DraftSuccess = 'draftSuccess',
  SuccessfullyScheduled = 'successfullyScheduled'
}

export enum Status {
  Pending = 'pending',
  Scheduled = 'scheduled',
  Paused = 'paused',
  InProgress = 'in-progress',
  Canceled = 'canceled',
  Finished = 'finished'
}

export enum FormTypes {
  Create = 'create',
  Update = 'update'
}

export enum OptionsPermissions {
  AllGroups = 'all-groups',
  AllAgents = 'all-agents',
  Everyone = 'everyone'
}

export enum ViewingConversations {
  Immediate = 'immediate',
  WhenAnswered = 'whenAnswered'
}

export enum TemplateCategoriesCredits {
  Marketing = 2,
  Utility = 1,
  Authentication = 1
}
