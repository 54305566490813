import { InboxTypes } from '@/common/helpers/enums'

import { ChatState } from '@/store/interfaces'

export const inboxCounters = (state: ChatState) => state.inboxCounters

export const inboxAllCounter = (state: ChatState) =>
  state?.inboxCounters[InboxTypes.ALL]

export const inboxByAgentCounter = (state: ChatState) =>
  state?.inboxCounters[InboxTypes.BY_AGENT]

export const inboxUnreadCounter = (state: ChatState) =>
  state?.inboxCounters[InboxTypes.UNREAD]

export const inboxMentionCounter = (state: ChatState) =>
  state.inboxCounters[InboxTypes.MENTIONED]

export const inboxParticipationCounter = (state: ChatState) =>
  state.inboxCounters[InboxTypes.PARTICIPATION]

export const inboxWithoutResponsibleCounter = (state: ChatState) =>
  state.inboxCounters[InboxTypes.WITHOUT_RESPONSIBLE]

export const inboxChatBotCounter = (state: ChatState) =>
  state.inboxCounters[InboxTypes.CHATBOT]

export const inboxTimeExceededCounter = (state: ChatState) =>
  state.inboxCounters[InboxTypes.TIME_EXCEEDED]

export const openChatConversationRoomKey = (state: ChatState) =>
  state.openChatConversationRoomKey

export const inboxGroups = (state: ChatState) => state.inboxCountersGroups

export const groupInboxNameSelected = (state: ChatState) =>
  state.groupInboxNameSelected

export const inboxGroupsEdit = (state: ChatState) => state.inboxGroupsEdit
