import i18n from '@/common/i18n'

import { RoomChannels } from '@/common/helpers/enums'

import { DataSourceResolver } from './interfaces'

export const roomChannels: DataSourceResolver = () =>
  Promise.resolve(
    Object.values(RoomChannels).map(val => ({
      id: val,
      name: i18n?.t(`dataSources.roomChannels.${val}`) as string
    }))
  )
