import { AppcuesUser } from '@/common/helpers/interfaces'

import { getUserLogged } from './storage'

export default class AppcuesService {
  identify(user: AppcuesUser) {
    if (window.Appcues) {
      const { id } = getUserLogged()
      if (id) {
        window.Appcues.identify(id, user)
      } else {
        console.error('Error in appcues identify: missing user id')
      }
    } else {
      console.error('Error in appcues identify: missing Appcues script')
    }
  }
  show(flow: string) {
    if (window.Appcues) {
      const { id } = getUserLogged()
      if (id) {
        window.Appcues.show(flow)
      } else {
        console.error('Error in appcues show: missing user id')
      }
    } else {
      console.error('Error in appcues show: missing Appcues script')
    }
  }
}
