import { CommerceOnboardingSteps } from '@/common/helpers/enums/commerce-onboarding'

import { CommerceOnboardingState } from '@/store/interfaces'

const state: CommerceOnboardingState = {
  initialDataLoader: true,
  currentOnboardingStep: CommerceOnboardingSteps.companySector,
  selectsOptions: {
    availableSectors: [],
    availableTools: []
  },
  companySectorId: '',
  companyCommerceType: undefined,
  aboutCompany: undefined,
  companyToolsIds: [],
  otherTools: ''
}

export default state
