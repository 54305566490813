import APIError from '@/common/errors/APIError'
import { AxiosError, AxiosInstance, CancelTokenSource } from 'axios'

import { getAuthState } from '@/common/services'
import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'
import {
  getNucleusAuthClient,
  getNucleusClient
} from '@/common/services/nucleus'

import { Checkout } from '@/store/modules/billing/checkout/types'

import { useStore } from './store'
import {
  NucleusBillingSaveRequest,
  ResponseNucleusPlan,
  SummaryResponse
} from './types'

let nucleusApi: AxiosInstance | undefined
let nucleusAuthApi: AxiosInstance | undefined

const headers = () => {
  const auth = getAuthState()
  const unloggedInfo = useStore().mapGetter('unloggedInfo')

  return {
    ...legacyHeaders().headers,
    ...(auth?.access_token
      ? { Authorization: `Bearer ${auth.access_token}` }
      : { Authorization: `Bearer ${unloggedInfo?.access_token}` })
  }
}

const getNucleusAPI = () => {
  if (nucleusApi) {
    return nucleusApi
  }
  nucleusApi = getNucleusClient()
  return nucleusApi
}

const getNucleusAuthAPI = () => {
  if (nucleusAuthApi) {
    return nucleusAuthApi
  }
  nucleusAuthApi = getNucleusAuthClient()
  return nucleusAuthApi
}

export const fetchPlans = (tenantId: string): Promise<ResponseNucleusPlan> => {
  return getNucleusAPI()
    .get<ResponseNucleusPlan>(`billing/${tenantId}/plans`, {
      params: { progress: true },
      headers: headers()
    })
    .then(r => r.data)
}

export const checkoutTransformToNucleus = (
  checkout: Checkout
): NucleusBillingSaveRequest => {
  const features = Object.values(checkout.items)
    .filter(item => !item.isExcludedFromCheckout)
    .map(({ code, qty }) => ({
      code,
      qty
    }))

  const plan = Object.values(checkout.items).find(
    item => item.isExcludedFromCheckout
  )

  const boleto = checkout.payment.code === 'Boleto'
  const creditCard = checkout.payment.details

  const paymentMethod = boleto ? { boleto } : { boleto, creditCard }

  const payload: NucleusBillingSaveRequest = {}

  if (features.length > 0) {
    payload.features = features
  }

  if (checkout.company.name) {
    payload.couponCode = checkout.couponCode
    payload.currency = ''
    payload.tenant = { ...checkout.company }
  }

  if (paymentMethod.boleto || paymentMethod.creditCard) {
    // @ts-ignore
    payload.paymentMethod = { ...paymentMethod }
  }

  if (plan?.code) {
    payload.planCode = plan.code
  }

  return payload
}

export interface Item {
  code: string
  name: string
  description: string
  disclaimer: string
  price: number
  qty: number
  total: number
  recurring: number
  proRata: number
}

export interface Discount {
  code: string
  name: string
  description: string
  value: number
}

export interface Payment {
  code?: any
  name: string
  description?: any
  value: number
}

export interface NucleusPreviewResponse {
  items: Item[]
  discounts: Discount[]
  payments: Payment[]
  disclaimers: string[]
  terms: boolean
  checkoutData: any[]
  isFranchiseCheckout: boolean
  couponCode?: string
}

export const billingSaveOrPreview = async (
  tenantId: string,
  data: any,
  preview = false,
  detailed = false,
  apiCancelToken: CancelTokenSource | undefined = undefined,
  withAllContractedFeatures = false
): Promise<NucleusPreviewResponse> => {
  const endpoint = `/Billing/${tenantId}/${preview ? 'preview' : 'save'}`

  data.fullPreview = detailed
  data.withAllContractedFeatures = withAllContractedFeatures

  const nucleusAPI = getNucleusAPI()

  try {
    const response = await nucleusAPI.post(endpoint, data, {
      params: { progress: true },
      headers: headers(),
      cancelToken: apiCancelToken?.token
    })
    return response.data
  } catch (err) {
    const error = err as AxiosError
    throw new APIError(error)
  }
}

export const processCheckout = (
  tenantId: string,
  checkout: Checkout,
  preview = false,
  detailed = false,
  apiCancelToken?: CancelTokenSource,
  withAllContractedFeatures?: boolean
) => {
  return billingSaveOrPreview(
    tenantId,
    checkoutTransformToNucleus(checkout),
    preview,
    detailed,
    apiCancelToken,
    withAllContractedFeatures
  )
}

export const fetchInvoices = (tenantId: string): Promise<any> => {
  return getNucleusAPI()
    .get(`SubscriptionManager/${tenantId}/invoices`, {
      params: { progress: true },
      headers: headers()
    })
    .then(r => r.data)
    .catch(error => {
      if (error) {
        console.error(error)
      }
    })
}

export const getNewInvoiceBankSlip = async ({
  tenantId,
  invoiceId
}: {
  tenantId: string
  invoiceId: string
}) => {
  const nucleusApi = getNucleusAPI()
  try {
    const response = await nucleusApi.get(
      `billing/${tenantId}/invoice/${invoiceId}/bankslip/url`,
      {
        headers: headers()
      }
    )

    return response.data
  } catch (err) {
    const error = err as AxiosError
    throw new APIError(error)
  }
}

export const reprocessInvoice = async ({
  tenantId,
  invoiceId
}: {
  tenantId: string
  invoiceId: string
}) => {
  const nucleusApi = getNucleusAPI()
  try {
    const response = await nucleusApi.post(
      `billing/${tenantId}/invoices/${invoiceId}/pay`,
      {},
      {
        headers: headers()
      }
    )

    return response.data
  } catch (err) {
    const error = err as AxiosError
    throw new APIError(error)
  }
}

export const fetchUsers = (): Promise<any> => {
  return getNucleusAPI()
    .get(`usersmanagement/users`, {
      params: { progress: true },
      headers: headers()
    })
    .then(r => r.data.results)
    .catch(erro => {
      if (erro.response) {
        console.error(erro.response)
      }
    })
}

export const getHasCreditCard = (subdomain: string) => {
  return getNucleusAPI().get(`/Billing/isNewHasCreditCard/${subdomain}`, {
    params: {},
    headers: headers()
  })
}

export const getOffers = async (subdomain: string) => {
  const client = await getClient('billing')
  const options = {
    params: {
      offerTypes: 'number',
      subDomain: subdomain
    },
    headers: {
      ...headers(),
      AppSubDomain: subdomain
    }
  }
  return client
    .get('/checkout/offers/', options)
    .then(r => r.data)
    .catch(err => {
      if (err.response) {
        console.error(err.response)
      }
    })
}

export const fetchSummary = (tenantId: string): Promise<SummaryResponse> => {
  return getNucleusAPI()
    .get(`Billing/${tenantId}/summary`, {
      params: { progress: true },
      headers: headers()
    })
    .then(r => r.data)
    .catch(error => {
      console.error(error)
    })
}

export const fetchCoupons = (tenantId: string): Promise<SummaryResponse> => {
  return getNucleusAPI()
    .get(`Tenants/${tenantId}/coupons`, {
      headers: headers()
    })
    .then(r => r.data)
    .catch(er => {
      if (er.response) {
        console.error(er.response)
      }
    })
}

export const validateCupon = (
  tenantId: string,
  coupon: string
): Promise<any> => {
  return getNucleusAPI()
    .post(
      `/billing/self-service/${tenantId}/${coupon}`,
      {},
      {
        headers: headers()
      }
    )
    .then(r => r.data)
    .catch(e => {
      if (e.response) {
        console.error(e.response)
      }
    })
}

export const getStatus = (): Promise<SummaryResponse> => {
  return getNucleusAuthAPI()
    .get(`billing/status`, {
      headers: headers()
    })
    .then(r => r.data)
}

export const getUserCoupons = (tenantId: string): Promise<any> => {
  return getNucleusAPI()
    .get(`Tenants/${tenantId}/coupons`, {
      params: { progress: true },
      headers: headers()
    })
    .then(r => r.data.results)
    .catch(error => {
      console.error(error)
    })
}

export const cancelPlan = (
  tenantId: string,
  churnReason: string,
  churnReasonDetail: string
): Promise<any> => {
  return getNucleusAPI()
    .post(
      `SubscriptionManager/${tenantId}/void`,
      {
        churnReason,
        churnReasonDetail
      },
      {
        headers: headers()
      }
    )
    .then(r => r.data)
    .catch(error => {
      console.error(error)
    })
}

export const removeUser = (tenantId: string, userId: string): Promise<any> => {
  return getNucleusAPI().delete(`usersmanagement/users/${userId}`, {
    headers: headers()
  })
}

type InvitePayload = {
  active: boolean
  admin: boolean
  email: string
  firstName?: string
  lastName?: string
  tenant?: { id: string }
}

export const inviteUsers = (tenantId: string, invites: InvitePayload[]) => {
  return Promise.all(
    invites.map(invite => {
      invite.tenant = { id: tenantId }
      return getNucleusAPI()
        .post(`usersmanagement/users`, invite, {
          headers: headers()
        })
        .then(r => r.data)
        .catch(error => {
          console.error(error)
        })
    })
  )
}

export const enableTenantFeatureTrialPeriod = async (featureCode: string) => {
  try {
    const nucleusAPI = getNucleusAPI()
    await nucleusAPI.post(
      `billing/tenantFeature/${featureCode}/trialPeriod/enable`,
      {},
      {
        headers: headers()
      }
    )
  } catch (err) {
    const error = err as AxiosError
    throw new APIError(error)
  }
}
