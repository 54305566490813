import { ActionContext } from 'vuex'

import { AppcuesUser, Event } from '@/common/helpers/interfaces'
import {
  getUtmValues,
  parseUtmsToRecord,
  getAnonymousId,
  getParameterByName
} from '@/common/helpers/utils'

import AnalyticsService from '@/common/services/analytics'
import AppcuesService from '@/common/services/appcues'
import DataLayerService from '@/common/services/data-layer'
import {
  subscribeNotifications,
  unsubscribeNotifications
} from '@/common/services/firebase'
import HubspotService from '@/common/services/hubspot'
import { connectSocket } from '@/common/services/socket'

import { RootState, AuthState } from '@/store/interfaces'

export const submitHubspotForm = async (_: any, payload: any) => {
  const hubspotService = new HubspotService()
  return await hubspotService.submitForm(undefined, undefined, payload)
}

export const sendDataLayerEvent = async (_: any, event: any) => {
  const dataLayerService = new DataLayerService()
  dataLayerService.insertDataLayerEvent(event)
}

export const identifyAppcuesUser = async (_: any, user: AppcuesUser) => {
  const appcuesService = new AppcuesService()
  appcuesService.identify(user)
}

export const showAppcuesFlow = async (_: any, flow: string) => {
  const appcuesService = new AppcuesService()
  appcuesService.show(flow)
}

export const trackEvent = async (
  context: ActionContext<AuthState, RootState>,
  properties: Event
) => {
  try {
    const anonymous_id = getAnonymousId()
    const company_id = context.rootState.auth!.userlogged?.subDomain
    const user_id = context.rootState.auth!.userlogged?.id
    const cycle_type = 2
    const device = navigator?.userAgent
    const role_type = context.rootState.auth!.userlogged?.roleType
    const gclid = getParameterByName('gclid') || ''

    const defaultProperties: Event = {
      anonymous_id,
      company_id,
      user_id,
      cycle_type,
      role_type,
      device,
      gclid
    }

    if (properties.new_analytics) {
      defaultProperties.utm_source = parseUtmsToRecord(getUtmValues())[0].source
      defaultProperties.utm_campaign = parseUtmsToRecord(
        getUtmValues()
      )[0].campaign
      defaultProperties.utm_medium = parseUtmsToRecord(getUtmValues())[0].medium
      defaultProperties.is_mobile = window.innerWidth < 762
      defaultProperties.event_location = 'new'
    } else {
      defaultProperties.properties = []
      defaultProperties.utms = parseUtmsToRecord(getUtmValues())
    }

    const event: Event = { ...defaultProperties, ...properties }

    if (properties.sendToGa) {
      const dataLayerEvent = await context.dispatch(
        'parseDataLayerAnalyticsEvent',
        event
      )
      const dataLayerService = new DataLayerService()
      dataLayerService.insertDataLayerEvent(dataLayerEvent, 'AnalyticsEvent')
      delete event.sendToGa
    }

    const analyticsService = new AnalyticsService()
    await analyticsService.send(event)
  } catch (ex) {
    console.error('Error in trackEvnt action: ', ex)
  }
}

export const parseDataLayerAnalyticsEvent = (_: any, event: Event) => {
  const parsedEvent: any = { ...event }
  parsedEvent.action = parsedEvent.name

  if (event.properties?.length) {
    parsedEvent.label = ''
    event.properties.forEach((property: Record<any, any>) => {
      parsedEvent.label += `${property.name}=${property.value};`
    })
  }
  return parsedEvent
}

export const startMonitoringEvents = (context: any) => {
  connectSocket(context)
  subscribeNotifications(context)
}

export const stopMonitoringEvents = () => unsubscribeNotifications()
