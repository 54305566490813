export enum HUBSPOT_ID {
  CHANNEL_CONFIGURED = 'product__canal_configurado',
  PLAN_SELECTED = 'product__checkout_visualizado',
  USER_ADDED = 'product__invite_convidado',
  DASHBOARD_VIEWED = 'product__report_visualizado'
}
export enum HUBSPOT_EVENT {
  WHATSAPP_WEB = 'whatsapp-web',
  PLAN_SELECTED = 'purchase plan selected',
  USER_ADDED = 'user added',
  DASHBOARD_VIEWED = 'dashboard viewed'
}
