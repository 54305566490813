import dayjs from 'dayjs'
import linkifyString from 'linkifyjs/string'

import timeFormat from '@/modules/Chat/components/conversation/filters/date/time'
import { isClient } from '@/modules/Chat/helpers'
import linkifyHtmlSanitizing from '@/modules/Chat/helpers/linkifyHtml'

String.prototype.hashCode =
  String.prototype.hashCode ||
  function () {
    let hash = 0,
      i,
      chr
    if (this.length === 0) return hash
    for (i = 0; i < this.length; i++) {
      chr = this.charCodeAt(i)
      hash = (hash << 5) - hash + chr
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  }

export const arrangeMessage = message => {
  if (!message.linkifiedComment) {
    try {
      message.comment = (message.comment || '').replace(
        /(?:\r\n|\r|\n)/g,
        '<br/>'
      )
      message.linkifiedComment = linkifyHtmlSanitizing(message.comment)
    } catch (err) {
      message.linkifiedComment = message.comment
    }
  }

  if (!message.timeFormatted) {
    message.timeFormatted = timeFormat(message.time)
  }

  return message
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const arrangeChat = (newChat, currentChat) => {
  if (newChat.messages) {
    if (
      currentChat &&
      currentChat.key === newChat.key &&
      currentChat.messages
    ) {
      const remainingMessages = currentChat.messages.filter(currentMessage => {
        return !newChat.messages.some(
          newMessage => newMessage.key === currentMessage.key
        )
      })

      if (remainingMessages.length) {
        newChat.messages = [...remainingMessages, ...newChat.messages]
        newChat.messages.sort((a, b) => {
          return new Date(a.time) > new Date(b.time) ? 1 : -1
        })
      }
    }

    newChat.messages.forEach(message => arrangeMessage(message))

    if (!newChat.createdBy) {
      const firstClientMessage = newChat.messages.find(
        m => m.user && isClient(m.user.type)
      )

      if (firstClientMessage) {
        newChat.createdBy = firstClientMessage.user
      }
    }
  }

  const lastMessage = newChat.lastMessage

  if (lastMessage && !lastMessage.linkifiedComment) {
    const linkifyFunction =
      lastMessage.type === 'automatic' ? linkifyHtmlSanitizing : linkifyString

    lastMessage.comment = (lastMessage.comment || '').replace(
      /(?:\r\n|\r|\n)/g,
      '<br/>'
    )

    lastMessage.linkifiedComment = linkifyFunction(lastMessage.comment)
  }

  if (newChat.lastMessageDate && !newChat.lastMessageMomentDate) {
    newChat.lastMessageMomentDate = dayjs(newChat.lastMessageDate)
  }

  if (newChat.closedDate && !newChat.closedMomentDate) {
    newChat.closedMomentDate = dayjs(newChat.closedDate)
  }

  return newChat
}
