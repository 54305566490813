import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(['trackEvent']),
    async trackEvent$(properties) {
      if (!properties.name) {
        throw new Error('Error on event track: missing event name')
      }

      await this.trackEvent(properties)
    }
  }
}
