<template>
  <div :class="container">
    <div :class="$style['loading__bubbles']"></div>
    <h4 :class="$style['loading__text']">{{ text }}</h4>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: ''
      },
      fullscreen: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      container() {
        return {
          [this.$style['loading']]: true,
          [this.$style['loading__fullscreen']]: this.fullscreen
        }
      }
    }
  }
</script>

<style lang="scss" module>
  .loading {
    @apply flex flex-col justify-center text-center h-full;

    &__fullscreen {
      @apply fixed inset-0 z-50 bg-white h-screen w-screen;
    }

    &__bubbles {
      @apply bg-center bg-no-repeat bg-contain h-80;
      background-image: url('/images/loading-bubbles.gif');
    }

    &__text {
      @apply font-poppins font-bold text-xl text-blueGray-800;
    }
  }
</style>
