import {
  Filters,
  OperatorTypes,
  contactsPerPage
} from '@/modules/Chat/components/sidebar/sidebar.enum.js'

export const filterPayload = {
  take: contactsPerPage,
  skip: 0,
  includeExcludedRecords: false,
  externalQueries: [
    {
      propertyName: Filters.TYPE,
      operator: OperatorTypes.EQUALS,
      valueCompare: 2
    },
    {
      propertyName: Filters.TEXT,
      operator: OperatorTypes.CONTAINS,
      valueCompare: ''
    }
  ],
  propertySort: 'Name',
  sortDirection: 'asc'
}
