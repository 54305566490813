import { FEATURE_CODE } from '@/common/helpers/enums/feature-code'
import {
  getParameterByName,
  getCookie,
  setCookie
} from '@/common/helpers/utils'

import store from '@/store'

export const isNewWidget = () => {
  const KEY = FEATURE_CODE.NEW_WIDGET

  const param = getParameterByName(KEY)

  const cookieValue = getCookie(KEY)

  const isDisabled = param === 'false' || cookieValue === 'false'

  const isEnabled = param === 'true' || cookieValue === 'true'

  if (param) setCookie(KEY, param)

  if (isDisabled) {
    return false
  } else if (isEnabled) {
    return true
  }

  if (!store?.getters) return false

  const { isNewWidget } = store.getters

  return isNewWidget
}
