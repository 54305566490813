import { Route } from 'vue-router'

import { socketEmit } from '@/common/services'

export const entryToTheSocketChatRoom = async (to: Route, from: Route) => {
  const chatKeyToRoom = to?.params?.key
  const chatKeyFromRoom = from?.params?.key

  if (from.meta?.isChatRoomRoute) {
    socketEmit('leaveChatRoom', chatKeyFromRoom)
  }
  if (to.meta?.isChatRoomRoute) {
    socketEmit('joinChatRoom', chatKeyToRoom)
  }
}
