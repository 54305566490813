import { ActionContext } from 'vuex'

import Storage from '@octadesk-tech/storage'

import services from '@/modules/ChatSettings/other-channels/chat/services'
import {
  OldWidgetSettings,
  OldWidgetState
} from '@/modules/ChatSettings/other-channels/chat/types'

import { getAnonymousId, getParameterByName } from '@/common/helpers/utils'

import AnalyticsService from '@/common/services/analytics'
import DataLayerService from '@/common/services/data-layer'

import { RootState } from '@/store/interfaces'

import * as types from './mutations-types'

export const saveSettings = async (
  context: ActionContext<OldWidgetState, RootState>,
  settings: OldWidgetSettings
) => {
  const resSettings = await services.saveSettings(settings)

  context.commit(types.SET_SETTINGS, resSettings.data)

  return resSettings.data
}

export const trackEvent = async (
  context: ActionContext<OldWidgetState, RootState>,
  properties: any
) => {
  try {
    const anonymous_id = getAnonymousId()
    const company_id = context.getters.userLogged.subDomain
    const user_id = context.getters.userLogged.id
    const cycle_type = context.getters.subDomainStatus?.cycleType
    const device = navigator.userAgent
    const role_type = context.getters.userLogged.roleType
    const gclid = getParameterByName('gclid')
    const defaultProperties: any = {
      anonymous_id,
      company_id,
      user_id,
      cycle_type,
      role_type,
      device,
      gclid
    }

    if (properties.new_analytics) {
      defaultProperties.utm_source = getParameterByName('utm_source')
      defaultProperties.utm_campaign = getParameterByName('utm_campaign')
      defaultProperties.utm_medium = getParameterByName('utm_medium')
      defaultProperties.is_mobile = window.innerWidth < 768
      defaultProperties.event_location = 'setup'
    } else {
      defaultProperties.properties = []
      defaultProperties.utms = []
    }

    const event = { ...defaultProperties, ...properties }

    if (properties.sendToGa) {
      const dataLayerEvent = await context.dispatch(
        'parseDataLayerAnalyticsEvent',
        event
      )
      const dataLayerService = new DataLayerService()
      dataLayerService.insertDataLayerEvent(dataLayerEvent, 'AnalyticsEvent')
      delete event.sendToGa
    }

    const analyticsService = new AnalyticsService()
    await analyticsService.send(event)
    context.dispatch('updateEventTiming', properties.name)
  } catch (ex) {
    console.error('Error in trackEvnt action: ', ex)
  }
}

export const setShowAgents = (
  context: ActionContext<OldWidgetState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_SHOW_AGENTS, value)
}

export const setTitle = (
  context: ActionContext<OldWidgetState, RootState>,
  value: string
) => {
  context.commit(types.SET_TITLE, value)
}

export const setIntroduction = (
  context: ActionContext<OldWidgetState, RootState>,
  value: string
) => {
  context.commit(types.SET_INTRODUCTION, value)
}

export const setUnavailableMessage = (
  context: ActionContext<OldWidgetState, RootState>,
  value: string
) => {
  context.commit(types.SET_UNAVAILABLE_MESSAGE, value)
}

export const showSendInstructionsModal = (
  context: ActionContext<OldWidgetState, RootState>
) => {
  context.commit(types.SHOW_SEND_INSTRUCTIONS_MODAL)
}

export const hideSendInstructionsModal = (
  context: ActionContext<OldWidgetState, RootState>
) => {
  context.commit(types.HIDE_SEND_INSTRUCTIONS_MODAL)
}

export const setInstructionsSent = (
  context: ActionContext<OldWidgetState, RootState>,
  state: boolean
) => {
  context.commit(types.INSTRUCTIONS_SENT, state)
}

export const enqueueInstructionsEmail = async (_: any, email: any) => {
  return await services.enqueueEmail(email)
}

export const setPosition = (
  context: ActionContext<OldWidgetState, RootState>,
  value: string
) => {
  context.commit(types.SET_POSITION, value)
}

export const setFontColorRgba = (
  context: ActionContext<OldWidgetState, RootState>,
  value: string
) => {
  context.commit(types.SET_FONT_COLOR_RGBA, value)
}

export const setBgColorRgba = (
  context: ActionContext<OldWidgetState, RootState>,
  value: string
) => {
  context.commit(types.SET_BG_COLOR_RGBA, value)
}

export const setLightness = (
  context: ActionContext<OldWidgetState, RootState>,
  value: number
) => {
  context.commit(types.SET_LIGHTNESS, value)
}

export const getUrlEmbed = (
  context: ActionContext<OldWidgetState, RootState>
) => {
  const url = services.getUrlEmbed()

  context.commit(types.SET_EMBED_CODE, url)

  return url
}

export const updateEventTiming = (
  _: ActionContext<OldWidgetState, RootState>,
  event: any
) => {
  const OCTA_EVENTS_TIMING = 'octa-events-timing'

  const eventsTiming: any = Storage.getItem(OCTA_EVENTS_TIMING)

  if (eventsTiming && (eventsTiming[event] || eventsTiming[event] === null)) {
    eventsTiming[event] = new Date().getTime()

    Storage.setItem(OCTA_EVENTS_TIMING, eventsTiming)
  }
}

export const parseDataLayerAnalyticsEvent = (
  _: ActionContext<OldWidgetState, RootState>,
  event: any
) => {
  const parsedEvent = { ...event }
  parsedEvent.action = parsedEvent.name

  if (event?.properties?.length) {
    parsedEvent.label = ''
    event.properties.forEach((property: any) => {
      parsedEvent.label += `${property.name}=${property.value};`
    })
  }
  return parsedEvent
}

export const fetchSettings = async (
  context: ActionContext<OldWidgetState, RootState>
) => {
  const settings = await services.getSettings()

  context.commit(types.SET_SETTINGS, settings)

  return settings
}
