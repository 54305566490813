import { ActionContext } from 'vuex'

import { environment } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

import { deleteAudioElement, soundNotification } from '@/modules/Chat/helpers'

import { getPrefixedDomain } from '@/common/helpers/utils'

import { NotificationCenterService } from '@/common/services'

import store from '@/store'
import { RootState } from '@/store/interfaces'

import {
  NotificationPayload,
  NotificationState,
  NotificationStatePayload
} from './interfaces'
import * as types from './mutations-types'

export const requestNotificationPermission = ({
  commit
}: ActionContext<NotificationState, RootState>) => {
  return new Promise((resolve, reject) => {
    if (!('Notification' in window)) {
      reject(new Error('Este navegador não suporta notificações!'))
    } else if (Notification.permission === 'granted') {
      commit(types.UPDATE_NOTIFICATION_PERMISSION, 'granted')

      resolve('Permissão já concedida!')
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          commit(types.UPDATE_NOTIFICATION_PERMISSION, 'granted')
          resolve('Permissão concedida!')
        } else {
          commit(types.UPDATE_NOTIFICATION_PERMISSION, permission)
          reject(new Error('Permissão negada!'))
        }
      })
    } else {
      reject(new Error('Permissão negada!'))
    }
  })
}

export const getNotificationsSettings = async ({
  commit
}: ActionContext<NotificationState, RootState>) => {
  try {
    const notificationCenterService = new NotificationCenterService()

    const response = await notificationCenterService.getNotificationsSettings()

    if (response) {
      commit(types.UPDATE_NOTIFICATION_SETTINGS, response)
    }

    return response
  } catch (e) {
    console.error(e)
  }
}

export const saveNotification = async (
  { commit, getters }: ActionContext<NotificationState, RootState>,
  notification: NotificationStatePayload
) => {
  try {
    if (getters.notificationPermission === `denied`) {
      throw new Error(
        'Permissão no navegador não liberada. Configurações não foram salvas.'
      )
    }

    const notificationCenterService = new NotificationCenterService()

    const userLogged = Storage.getItem('userLogged') as any

    const IdCompany = store.getters.companyModel.company.idCompany

    const notificationPayload: NotificationPayload = {
      ...notification,
      UserId: userLogged.id as string,
      IdCompany
    }

    const isEditing = getters.notificationSettings

    const action = isEditing
      ? notificationCenterService.updateNotification(
          getters.notificationSettings.id,
          notificationPayload
        )
      : notificationCenterService.createNotification(notificationPayload)

    const response = await action

    const data = isEditing
      ? { ...notificationPayload, id: getters.notificationSettings.id }
      : response

    commit(types.UPDATE_NOTIFICATION_SETTINGS, data)

    deleteAudioElement()

    store.dispatch('dispatchAlert', {
      variant: 'success',
      body: 'Notificações configuradas com sucesso.'
    })
  } catch (e) {
    console.error(e)

    store.dispatch('dispatchAlert', {
      variant: 'danger',
      body:
        (e as { message: string }).message ||
        `Erro ao configurar notificações. Tente novamente.`
    })
  }
}

export const showVisualNotification = async (
  _context: ActionContext<NotificationState, RootState>,
  notification: {
    title: string
    body: string
    icon: string
    sound: string
    data: {
      roomKey?: string
    }
  }
) => {
  const notify = new Notification(notification.title, {
    ...notification,
    icon: '/images/Icons/favicon.ico'
  })

  if (notify?.data?.roomKey) {
    if (!localStorage.getItem('tabOpen')) {
      localStorage.setItem('tabOpen', 'true')
    }

    notify.onclick = () => {
      const urlSubdomain =
        environment.getEnvironment() === 'beta' ? 'beta' : 'app'

      const urlBase = getPrefixedDomain()

      const url = `https://${urlSubdomain}.${urlBase}.com/chat/${notify.data.roomKey}/all`

      if (localStorage.getItem('tabOpen')) {
        window.focus()

        window.location.href = url
      } else {
        window.open(url, '_blank')
      }
    }
  }
}

export const pushSoundNotification = (
  _context: ActionContext<NotificationState, RootState>,
  sound: string
) => {
  soundNotification(sound)
}
