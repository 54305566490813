import { ActionContext } from 'vuex'

import {
  CommerceOnboardingSteps,
  CompanyCommerceTypes,
  AboutCompanyOptions,
  AvailableTools
} from '@/common/helpers/enums/commerce-onboarding'

import { CommerceOnboardingService } from '@/common/services'

import {
  RootState,
  CommerceOnboardingState,
  CommerceOnboardingAvailableTools,
  CommerceOnboardingAvailableSectors
} from '@/store/interfaces'

import * as types from './mutations-types'

export const setCommerceOnboardingStep = async (
  context: ActionContext<CommerceOnboardingState, RootState>,
  step: CommerceOnboardingSteps
) => {
  context.commit(types.SET_COMMERCE_ONBOARDING_STEP, step)
}

export const setCommerceOnboardingInitialDataLoader = async (
  context: ActionContext<CommerceOnboardingState, RootState>,
  initialDataLoader: boolean
) => {
  context.commit(
    types.SET_COMMERCE_ONBOARDING_INITIAL_DATA_LOADER,
    initialDataLoader
  )
}

export const getCommerceOnboardingAvailableSectors = async (
  context: ActionContext<CommerceOnboardingState, RootState>
) => {
  const commerceOnboardingService = new CommerceOnboardingService()

  const sectors =
    await commerceOnboardingService.getCommerceOnboardingAvailableSectors()

  context.commit(types.SET_COMMERCE_ONBOARDING_AVAILABLE_SECTORS, sectors)
}

export const getCommerceOnboardingAvailableTools = async (
  context: ActionContext<CommerceOnboardingState, RootState>
) => {
  const commerceOnboardingService = new CommerceOnboardingService()

  const availableTools =
    await commerceOnboardingService.getCommerceOnboardingAvailableTools()

  context.commit(types.SET_COMMERCE_ONBOARDING_AVAILABLE_TOOLS, availableTools)
}

export const setCommerceOnboardingCompanySectorId = (
  context: ActionContext<CommerceOnboardingState, RootState>,
  companySectorId: string
) => {
  context.commit(
    types.SET_COMMERCE_ONBOARDING_COMPANY_SECTOR_ID,
    companySectorId
  )
}

export const setCommerceOnboardingCompanyCommerceType = (
  context: ActionContext<CommerceOnboardingState, RootState>,
  companyCommerceType: CompanyCommerceTypes
) => {
  context.commit(
    types.SET_COMMERCE_ONBOARDING_COMPANY_COMMERCE_TYPE,
    companyCommerceType
  )
}

export const setCommerceOnboardingAboutCompany = (
  context: ActionContext<CommerceOnboardingState, RootState>,
  aboutCompany: AboutCompanyOptions
) => {
  context.commit(types.SET_COMMERCE_ONBOARDING_ABOUT_COMPANY, aboutCompany)
}

export const setCommerceOnboardingCompanyToolsIds = (
  context: ActionContext<CommerceOnboardingState, RootState>,
  tool: AvailableTools
) => {
  const { commerceOnboardingCompanyTools } = context.getters

  const tools = commerceOnboardingCompanyTools

  const toolAlreadySelected = tools.findIndex(
    (item: AvailableTools) => item === tool
  )

  if (toolAlreadySelected >= 0) {
    tools.splice(toolAlreadySelected, 1)
  } else {
    tools.push(tool)
  }

  context.commit(types.SET_COMMERCE_ONBOARDING_COMPANY_TOOLS_IDS, tools)
}

export const clearCommerceOnboardingCompanyTools = (
  context: ActionContext<CommerceOnboardingState, RootState>
) => {
  context.commit(types.SET_COMMERCE_ONBOARDING_COMPANY_TOOLS_IDS, [])
}

export const setCommerceOnboardingOtherTools = (
  context: ActionContext<CommerceOnboardingState, RootState>,
  otherTools: string
) => {
  context.commit(types.SET_COMMERCE_OTHER_TOOLS, otherTools)
}

export const saveCommerceOnboardingProps = async (
  context: ActionContext<CommerceOnboardingState, RootState>
) => {
  const {
    commerceOnboardingAvailableSectors,
    commerceOnboardingCompanySectorId,
    commerceOnboardingCompanyCommerceType,
    commerceOnboardingAboutCompany,
    commerceOnboardingCompanyTools,
    commerceOnboardingOthersTools
  } = context.getters

  const payload = {
    companySectorId: commerceOnboardingAvailableSectors.find(
      (item: CommerceOnboardingAvailableSectors) =>
        item.code === commerceOnboardingCompanySectorId
    )?.id,
    companyCommerceType: commerceOnboardingCompanyCommerceType,
    aboutCompany: commerceOnboardingAboutCompany?.id,
    companyToolsIds: commerceOnboardingCompanyTools.map(
      (item: CommerceOnboardingAvailableTools) => item.id
    ),
    otherTools: commerceOnboardingOthersTools.split(',')
  }

  const commerceOnboardingService = new CommerceOnboardingService()

  await commerceOnboardingService.saveCommerceOnboardingProps(payload)
}
