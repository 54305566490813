import { objectId, getCookie, setCookie } from '@/common/helpers/utils'

export const getAnonymousId = () => {
  const cookie = getCookie('octa-anonymous-id')
  if (cookie) {
    return cookie
  } else {
    const id = objectId()
    setCookie('octa-anonymous-id', id)
    return id
  }
}
