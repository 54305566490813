import { PersonTypes, ChatStatus } from '@/common/helpers/enums'

export default (limit: number, userLoggedId: string) => ({
  'agent._id': userLoggedId,
  status: ChatStatus.TALKING,
  lastMessageUserType: {
    $in: [PersonTypes.System, PersonTypes.Customer, PersonTypes.None]
  },
  limit
})
