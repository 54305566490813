import { ChatSettings } from '@/common/helpers/interfaces'

import { ChatState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_INBOX_COUNTERS](
    state: ChatState,
    counters: Record<string, number | undefined>
  ) {
    state.inboxCounters = counters
  },
  [types.UPDATE_CHAT_SETTINGS](state: ChatState, settings: ChatSettings) {
    state.chatSettings = settings
  },
  [types.OPEN_CHAT_CONVERSATION_ROOM_KEY](state: ChatState, roomKey: string) {
    state.openChatConversationRoomKey = roomKey
  },
  [types.SET_INBOX_GROUPS](state: ChatState, groups: any) {
    state.inboxCountersGroups = groups
  },
  [types.SET_GROUP_INBOX_NAME_SELECTED](
    state: ChatState,
    groupInboxNameSelected: string
  ) {
    state.groupInboxNameSelected = groupInboxNameSelected
  },
  [types.SET_INBOX_GROUPS_EDIT](state: ChatState, groups: any) {
    state.inboxGroupsEdit = groups
  }
}
