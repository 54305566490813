export enum ADD_CONTENT_TABS {
  FILES = 0,
  URLS = 1
}

export enum WOZ_MEMORY_SOURCE {
  KnowledgeBase = 'KnowledgeBase',
  Upload = 'Upload',
  WebCrawler = 'WebCrawler'
}
export enum DOCUMENT_STATUS {
  ENQUEUED = 'ENQUEUED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR'
}
