import Vue, { PluginObject } from 'vue'

import OctaAnalyticsClient from '@octadesk-tech/analytics-client'
import { subDomain } from '@octadesk-tech/services'

import { getUserLogged } from '@/common/services'

type AnalyticsEngine =
  | 'segment'
  | 'segmentIdentify'
  | 'bigquery'
  | 'gtm'
  | 'mixpanel'
  | 'general'
export type AnalyticsOptions = {
  engine: AnalyticsEngine | AnalyticsEngine[]
}
type AnalyticsFn = (
  name: string,
  meta: Record<string, unknown>,
  options?: AnalyticsOptions
) => void

type AnalyticsEngines = {
  [T in AnalyticsEngine]: (name: string, meta: Record<string, unknown>) => void
}

function debugWarning(message: string) {
  if (import.meta.env.MODE !== 'production') {
    console.warn(`[Analytics][Warn]: ${message}`)
  }
}

const engines: AnalyticsEngines = {
  bigquery(name: string, meta: Record<string, unknown>): void {
    OctaAnalyticsClient.BigQueryIntegration.send({ name, ...meta }).catch(
      debugWarning
    )
  },
  general(name: string, meta: Record<string, unknown>): void {
    OctaAnalyticsClient.GeneralIntegration.track(name, meta).catch(debugWarning)
  },
  gtm(name: string, meta: Record<string, unknown>): void {
    try {
      OctaAnalyticsClient.GtmIntegration.insertDataLayerEvent(meta, name)
    } catch (e) {
      debugWarning(e as string)
    }
  },
  mixpanel(name: string, meta: Record<string, unknown>): void {
    if (OctaAnalyticsClient.MixpanelIntegration.mixpanel) {
      OctaAnalyticsClient.MixpanelIntegration.mixpanel
        .track(name, meta)
        .catch(debugWarning)
      return
    }

    debugWarning('Mixpanel is not present in this application.')
  },
  segment(name: string, meta: Record<string, unknown>): void {
    OctaAnalyticsClient.SegmentIntegration.track(name, meta).catch(debugWarning)
  },
  segmentIdentify(): void {
    // This is a temporary solution to avoid breaking the app
    // When getUserLogged is removed the app will break because of i18n initialization
    getUserLogged()
  }
}

function debugFn(engineName: AnalyticsEngine, name: string, message: any) {
  if (import.meta.env.MODE !== 'production') {
    console.info(
      '%cAnalytics',
      'background: #E37400; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
      `[${engineName}][${name}]`,
      JSON.stringify(message)
    )
  }
}

export const analytics: AnalyticsFn = (
  name,
  meta,
  options = { engine: 'segment' }
) => {
  debugWarning(`Using engines: ${JSON.stringify(options.engine)}`)

  const parsedMeta = {
    ...meta,
    company_id: subDomain.getSubDomain()
  }

  if (Array.isArray(options.engine)) {
    options.engine.forEach(engineName => {
      engines[engineName](name, parsedMeta)

      debugFn(engineName, name, parsedMeta)
    })

    return
  }

  engines[options.engine](name, parsedMeta)

  debugFn(options.engine, name, parsedMeta)
}

const plugin: PluginObject<{ $analytics: AnalyticsFn }> = {
  install(Vue) {
    Vue.prototype.$analytics = analytics
  }
}

declare module 'vue/types/vue' {
  export interface Vue {
    $analytics: AnalyticsFn
  }
}

Vue.use(plugin)
