import * as i18nModules from '@/modules/i18n'

import { LanguageTypes } from '@/common/helpers/enums'

import enUS from './en-US.json'
import ptBR from './pt-BR.json'

interface TranslateType {
  [key: string]: any
}

const allLangs: TranslateType = {
  'pt-BR': ptBR,
  'en-US': enUS
}

const i18n: TranslateType = {}

Object.values(LanguageTypes).forEach((lang: string) => {
  i18n[lang] = {
    ...allLangs[lang]
  }

  const modulesNames = Object.keys(i18nModules)

  Object.values(i18nModules).forEach((item, moduleKey) => {
    const componentsNames = Object.keys(item)

    if (!i18n[lang][modulesNames[moduleKey]]) {
      i18n[lang][modulesNames[moduleKey]] = {}
    }

    Object.values(item).forEach((item: TranslateType, componentKey) => {
      if (!i18n[lang][modulesNames[moduleKey]][componentsNames[componentKey]]) {
        i18n[lang][modulesNames[moduleKey]][componentsNames[componentKey]] = {}
      }

      i18n[lang][modulesNames[moduleKey]][componentsNames[componentKey]] =
        item[lang]
    })
  })
})

export default i18n
