import { mapGetters, mapActions } from 'vuex'

import { getCookie, hubspotEvents, getUtmValues } from '@/common/helpers/utils'

export default {
  data() {
    return {
      hubspotPayload$: {
        fields: [],
        context: {
          pageUri: window.location.href,
          pageName: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userLogged'])
  },
  methods: {
    ...mapActions(['submitHubspotForm']),
    async handleHubspotSubmit$() {
      this.handleUtms$()

      this.handleHubspotEmailInjection$()

      this.handleHutkCookie$()

      await this.submitHubspotForm(this.hubspotPayload$)

      this.hubspotPayload$.fields = []
    },
    handleUtms$() {
      const utms = getUtmValues()

      if (utms) {
        for (const utm in utms) {
          const value = utms[utm]

          if (value) {
            this.hubspotPayload$.fields.push({
              name: utm,
              value
            })
          }
        }
      }
    },
    handleHubspotEmailInjection$() {
      const hasEmail = this.hubspotPayload$.fields.some(
        field => field.name === 'email'
      )

      if (!hasEmail) {
        this.hubspotPayload$.fields.push({
          name: 'email',
          value: this.userLogged?.email
        })
      }
    },
    handleHutkCookie$() {
      const cookie = getCookie('hubspotutk')

      if (cookie) {
        this.hubspotPayload$.context.hutk = cookie
      }
    },
    $_sendHubspotPqlEvent(event) {
      try {
        const hubspotFields = hubspotEvents[event]

        this.hubspotPayload$.fields.push(...hubspotFields)

        this.handleHubspotSubmit$()
      } catch (e) {
        console.error(e)
      }
    }
  }
}
