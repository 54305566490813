import { fetchConvertedMp3Audio } from '@/modules/Chat/services/chat'

import browser from './browser'

class FileRegexes {
  get video() {
    return (this._video = this._video || /(mp4|avi)$/i)
  }

  get image() {
    return (this._image = this._image || /(jpg|jpeg|png|gif|web|webp|svg)$/i)
  }

  get audio() {
    return (this._audio =
      this._audio || /(mp3|ogg|wav|opus|webm|aac|mp4a|m4a)$/i)
  }
}

const fileRegexes = new FileRegexes()

const fileTypes = {
  parseExtension(url) {
    if (!url) return ''
    return url.split('.').reverse()[0]
  },
  isImage(url) {
    return url && fileRegexes.image.test(this.parseExtension(url))
  },
  isAudio(url) {
    return url && fileRegexes.audio.test(this.parseExtension(url))
  },
  isVideo(url, mimeType) {
    return (
      (url && fileRegexes.video.test(this.parseExtension(url))) ||
      mimeType === 'video'
    )
  },
  isWebp(url) {
    return url && this.parseExtension(url) === 'webp'
  },
  isGif(url) {
    return url && this.parseExtension(url) === 'gif'
  },
  isSvg(url) {
    return url && this.parseExtension(url) === 'svg'
  },
  isOpusAudio(url) {
    return url && ['opus', 'ogg'].includes(this.parseExtension(url))
  },
  isPdf(fileProp) {
    return !!(fileProp && fileProp.endsWith('pdf'))
  },
  isMacAndOpusAudio(url) {
    return browser.isMacLike() && this.isOpusAudio(url)
  },
  async convertedMp3Url(url, subdomain) {
    try {
      return `/file-conversor?targetmimetype=audio/mp3&url=${url}&sd=${subdomain}`
    } catch (err) {
      console.error('Error in convertedMp3Url', err)
    }
  },
  async resolvedAudioUrl(pttUrl, url, company) {
    if (pttUrl && !this.isMacAndOpusAudio(pttUrl)) {
      return pttUrl
    }

    if (!this.isMacAndOpusAudio(url)) {
      return url
    }

    const conversorUrl = await this.convertedMp3Url(url, company)
    const convertedAudioBlob = await fetchConvertedMp3Audio(conversorUrl)
    const resolvedUrl = URL.createObjectURL(convertedAudioBlob)

    const resolvedData = {
      blob: resolvedUrl,
      isConverted: true
    }

    URL.revokeObjectURL(convertedAudioBlob)
    return resolvedData
  }
}

export default fileTypes
