import { trackingHandlers } from '@/common/tracking'

import OctaAnalyticsClient from '@octadesk-tech/analytics-client'

import { EVENTS } from '@/common/helpers/enums'

export class EventTrackingService {
  static readonly events: Array<Record<string, string | ((data: any) => any)>> =
    [
      {
        name: EVENTS.USER_ADD_PAGEVIEW,
        handler: async (e: CustomEvent) => {
          const { detail } = e

          const { _resolver, _reject } = detail

          try {
            const tasks: Promise<void>[] = trackingHandlers?.[
              EVENTS.USER_ADD_PAGEVIEW
            ].map(async h => await h())

            await Promise.all(tasks)

            _resolver()
          } catch (ex) {
            _reject(ex)
          }
        }
      },

      {
        name: EVENTS.NEW_PRODUCT_ROLLBACK,
        handler: async (e: CustomEvent) => {
          const { detail } = e

          const { _resolver, _reject } = detail

          try {
            const tasks: Promise<void>[] = trackingHandlers?.[
              EVENTS.NEW_PRODUCT_ROLLBACK
            ].map(async h => await h(detail))

            await Promise.all(tasks)

            _resolver()
          } catch (ex) {
            _reject(ex)
          }
        }
      },

      {
        name: EVENTS.SEND_FEEDBACK,
        handler: async (e: CustomEvent) => {
          const { detail } = e

          const { _resolver, _reject } = detail

          try {
            const tasks: Promise<void>[] = trackingHandlers?.[
              EVENTS.SEND_FEEDBACK
            ].map(async h => await h(detail))

            await Promise.all(tasks)

            _resolver()
          } catch (ex) {
            _reject(ex)
          }
        }
      }
    ]

  static listenEvents() {
    this.events.forEach((listener: Record<string, any>) => {
      OctaAnalyticsClient.GeneralIntegration.onEvent(
        listener.name,
        listener.handler
      )
    })
  }
}
