import OctaAnalyticsClient from '@octadesk-tech/analytics-client'

import { EVENTS, PRODUCT_TARGETS, LOCATION_TYPES } from '@/common/helpers/enums'

export const segmentTracking = async () => {
  await OctaAnalyticsClient.SegmentIntegration.track(EVENTS.USER_ADD_PAGEVIEW, {
    target: PRODUCT_TARGETS.NEW_PRODUCT,
    location: LOCATION_TYPES.CONFIG
  })
}
