import OctaAnalyticsClient from '@octadesk-tech/analytics-client'

import { EVENTS, PRODUCT_TARGETS } from '@/common/helpers/enums'

interface TrackingProperties {
  source?: string
}

export const segmentTracking = async ({ source }: TrackingProperties) => {
  await OctaAnalyticsClient.SegmentIntegration.track(EVENTS.SEND_FEEDBACK, {
    target: PRODUCT_TARGETS.NEW_PRODUCT,
    source
  })
}
