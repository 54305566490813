import { EmojiIndex } from 'emoji-mart-vue-fast'
import emojiRegex from 'emoji-regex'

import data from './emoji-data.json'
import { REGEX_TEXT_TO_EMOJI } from './emoji-regex-list'

const UNICODE_EMOJI_REGEX = emojiRegex()
const EMOJI_INSTANCE = new EmojiIndex(data)

const textToEmoji = function (text) {
  return text.replace(
    REGEX_TEXT_TO_EMOJI,
    (match, ...args) =>
      EMOJI_INSTANCE.search(args[0] ?? args[1])[0]?.native ?? match
  )
}

const emojiHelper = {
  textToEmoji,
  index: EMOJI_INSTANCE,
  regex: UNICODE_EMOJI_REGEX
}
export default emojiHelper
