import { GlobalState } from '@/store/interfaces'

import * as types from './mutation-types'

export default {
  [types.SET_GROUPS](state: GlobalState, groups: any) {
    state.groups = groups
  },
  [types.SET_AGENTS](state: GlobalState, agents: any) {
    state.agents = agents
  },
  [types.SET_RELOAD_PAGE_ALERT](state: GlobalState) {
    state.reloadPage = true
  }
}
